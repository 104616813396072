/**
 * 決済キャンセル画面用のJS
 */
(function () {
    this.ManageCancelPayment = (function () {
        function ManageCancelPayment() {
            /**
             * Vueオブジェクト
             * @type {Vue}
             */
             new Vue({
                el: '[data-view="manage_cancel_payment"]',
                /**
                 * ミックスインオブジェクト
                 */
                mixins: [
                    CommonMixin,
                ],
                /**
                 * コンポーネント群
                 */
                components: {},

                /**
                 * データ群
                 */
                data: function () {
                    return {
                        message: ''
                    }
                },

                /**
                 * インスタンス生成時に実行される処理
                 */
                created: function () {
                },

                 /**
                  * 画面描画後に実行される処理
                  */
                 mounted: function () {
                     this.Common = new Common();

                     $.ajaxSetup({
                         type: 'POST',
                         dataType: 'json',
                         cache: false,
                         timeout: 30000, // 30秒
                     });
                 },

                /**
                 * 各種メソッド
                 */
                methods: {

                    /**
                     * フォームのsubmit処理
                     */
                    submit: function () {

                        this.message = '';
                        var _this = this;
                        var $form = $('#payment_form');

                        // VueのDOM更新は非同期のため、DOMの更新が全て終わってから処理を行う
                        Vue.nextTick(function () {

                            vex.dialog.confirm({
                                message: '決済を取り消します。よろしいですか？',
                                callback: function (value) {
                                    if (value === true) {
                                        _this.Common.ajaxSubmit($form.attr('action'), $form.attr('method'), $form.serialize(),{
                                            success: function(data, textStatus, xhr) {
                                                console.log(data);
                                                if (!data.errors.length) {
                                                    location.href='/manage_cancel_payment/finish'; // 完了画面に遷移
                                                }else{
                                                    _this.message = data.errors.join("<br>");
                                                }
                                            }
                                        });
                                    }
                                }
                            });
                        });
                    },
                }
            });
        }

        return ManageCancelPayment;
    })();
}).call(this);
