/**
 * アカウント設定画面用のJS
 */
(function () {
    this.Account = (function () {
        function Account() {
            this.setListener();
        }

        Account.prototype.setListener = function () {
            $('input[id=delete_password]').on('keyup input', function(event) {
                var val = $(this).val();
                if(val === "削除"){
                    $('[data-action="delete_submit"]').removeClass('inactive');
                    button.disabled = false;
                } else {
                    $('[data-action="delete_submit"]').addClass('inactive');
                    button.disabled = true;
                }
            });
            $("input"). keydown(function(e) {
                if ((e.which && e.which === 13) || (e.keyCode && e.keyCode === 13)) {
                    return false;
                } else {
                    return true;
                }
            })
        };
        return Account;
    })();
}).call(this);
