/**
 * 管理画面共通のJS
 */
(function () {
    $(function () {
        var common = new Common();

        // Vex初期設定
        vex.defaultOptions.className = 'vex-theme-os';
        vex.dialog.buttons.NO.text = 'キャンセル';

        /**
         * SweatAlert2
         * @param  {string} text
         * @param  {Object} params 追加パラメータを指定可能
         * @return {Promise<SweetAlertResult>}
         */
        window.swalToast = function (text, params = {}) {
            return Swal.fire(Object.assign({
                toast: true, // トースト表示
                position: 'bottom', // 下部
                icon: 'success', // デフォルトでsuccess
                showConfirmButton: false,
                showCancelButton: false,
                timer: 3000,
                text: text,
                customClass: {
                    container: 'swal-toast-container', // コンテナのクラスをオーバーライド
                },
                showClass: {
                    popup: 'fadein',
                },
                hideClass: {
                    popup: 'fadeout',
                },
                showCloseButton : true,
            }, params));
        };

        // SweatAlert表示するメッセージがある場合は表示
        if (!!$('#toast_message').data('text')) {
            swalToast($('#toast_message').data('text'), {icon: 'success'});
        }
        if (!!$('#toast_error').data('text')) {
            swalToast($('#toast_error').data('text'), {icon: 'error'});
        }
        if (!!$('#toast_warning').data('text')) {
            swalToast($('#toast_warning').data('text'), {icon: 'warning'});
        }
        if (!!$('#vex_message').data('text')) {
            // vex表示するメッセージがある場合は表示
            vex.dialog.alert({unsafeMessage:$('#vex_message').data('text')});
        }

        // ウィンドウのY軸位置
        var window_y = 0;

        // blockUIの初期設定
        $.blockUI.defaults.message = '<div class="spinner"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div>';
        $.blockUI.defaults.css.background = 'none';
        $.blockUI.defaults.css.border = 'none';
        $.blockUI.defaults.css['z-index'] = '2147483647';
        $.blockUI.defaults.overlayCSS.backgroundColor = 'rgba(255, 255, 255, .8)';
        $.blockUI.defaults.overlayCSS.opacity = '1';
        $.blockUI.defaults.baseZ = '2147483637';

        // flatpickrの初期設定
        flatpickr.localize(flatpickr.l10ns.ja);
        flatpickr.l10ns.default.firstDayOfWeek = 1; // 月曜始まり
        // flatpickrの適用
        $('.flatpickr').flatpickr();
        $('.flatpickr_time').flatpickr({
            enableTime: true,
            noCalendar: true,
            dateFormat: "H:i",
            time_24hr: true,
        });

        // validatorの機能拡張
        // 標準のisEmpty()ではundefinedとnullのチェックができないので拡張
        validator.isBlank = function (value) {
            if (value === undefined || value === null) {
                return true;
            }

            return validator.isEmpty(value + '');
        };

        /**
         * 共通のsubmit処理
         * data-action="submit"が設定されている要素クリック時に実行される（通常はblockuiを表示）
         * data-block-messageが設定されている場合はblockui時に指定のメッセージを表示する（未指定時は「保存しています...」を表示）
         * data-block="false"が設定されている場合はsubmit時にblockuiを表示しない
         */
        $(document).on('click', 'form [data-action="submit"]', function () {
            var $form = $(this).closest('form');
            var block_message = $.blockUI.defaults.message;
            if( $(this).data("block-message") ) {
                block_message = $.blockUI.defaults.message + '<div class="block_msg">' + $(this).data("block-message") + '</div>';
            }
            if ($(this).data('block') !== false) {
                $.blockUI({
                    message: block_message,
                    onBlock: function () {
                        $form.closest('form').submit();
                        // 画面遷移前にはblockUIを解除（iOS利用時ブラウザバック対応）
                        if (navigator.userAgent.indexOf('iPhone') != -1 ||
                            navigator.userAgent.indexOf('iPad') != -1) {
                            $.unblockUI();
                        }
                    }
                });
            }
        });

        /**
         * 共通のajax_submit処理
         */
        $(document).on('click', '[data-action="ajax_submit"]', function () {
            common.ajaxFormSubmit($(this).closest('form'));
        });

        var _scrollY = 0;

        /**
         * ドロワーメニュー切り替え
         */
        $('[data-action="drawer"]').click(function () {

            $('body').toggleClass('menu_active');
            if( $('body').hasClass('menu_active') ) {

                _scrollY = $(window).scrollTop();

                // 表示
                $("main").hide();
                $(".manage_footer").hide();
                $(".menu").fadeIn();

                $(window).scrollTop(0);
            } else {
                $(".menu").hide();
                $("main").fadeIn();
                $(".manage_footer").fadeIn();

                $(window).scrollTop(_scrollY);
                _scrollY = 0;
            }
            return false;
        });

        /**
         * ホームに戻る
         */
        $('[data-action="back_home"]').click(function () {
            window.location.href = "/";
            return false;
        });

        // Enterキーでformをsubmitさせない
        $(document).on('keydown', 'input[data-action="enter_disable"]', function (e) {
            var keyCode = e.keyCode || e.which;
            if (keyCode === 13) {
                e.preventDefault();
                return false;
            }
        });

        $(document).on('opened', '[data-remodal-id="invitation_select_modal"]', function () {
            // モーダルが表示完了したらボタンを表示（2重モーダル対策）
            $(this).find('[data-action="create"], [data-action="delete"]').show();
        });

        if (!!$('.remodal[data-first-show="true"]')[0]) {
            $('.remodal[data-first-show="true"]').remodal({hashTracking: false}).open();
        }

        /**
         * リモーダル開いているとき
         */
        $(document).on('opening', '.remodal', function () {
            if ($(this).find(':input').length) {
                // ウィンドウのY軸位置を記憶
                window_y = window.pageYOffset;
                // iOSでinputタグにフォーカス時に縦スクロールが生じる事象調整
                $('body').css('overflow-y', 'hidden');
            }
        });

        /**
         * リモーダル閉じられたとき
         */
        $(document).on('closing', '.remodal', function () {
            if ($(this).find(':input').length) {
                // iOSでinputタグにフォーカス時に縦スクロールが生じる事象調整※元に戻す
                $('body').css('overflow-y', '');
                // ウィンドウのY軸位置を元に戻す
                $(window).scrollTop(window_y);
            }
        });

        /**
         * フォーカス時全選択になるテキストボックス
         */
        $('#auto_select').click(function () {
            this.selectionStart = 0;
            this.selectionEnd = this.value.length;
        });

        /**
         * アカウント削除ボタンの処理（confirmしてからformをsubmit）
         */
        $('[data-action="delete_submit"]').click(function () {
            var $form = $(this).closest('#delete_form');
            var pwd = $("#delete_password").val();
            vex.dialog.confirm({
                message: '一度アカウントを削除すると元に戻すことはできません。アカウントを削除します。本当によろしいですか？',
                callback: function (value) {
                    if (value === true) {
                        $.blockUI();
                        $("#delete_password").val(pwd); // autocomplete bug hack
                        $form.submit();
                    }
                }
            });
            return false;
        });

        /**
         * LINEアカウント連携解除処理（confirmしてからformをsubmit）
         */
        $('[data-action="remove_line_connect"]').click(function () {
            var password = $(this).closest('[data-role="account_action"]').find('input[name="is_password"]').val();
            if (password) {
                $message = 'LINEアカウントでのログイン連携を解除します。よろしいですか？<br>※解除後はメールアドレスでのログインのみ可能となります。';
                var $form = $(this).closest('#remove_form');
                vex.dialog.confirm({
                    unsafeMessage: $message,
                    callback: function (value) {
                        if (value === true) {
                            $.blockUI();
                            $form.submit();
                        }
                    }
                });
            } else {
                $message = 'LINEの連携解除はメールログイン時のパスワードが設定されている場合のみ可能です。<br>下記よりパスワードの設定を行ってから、再度実行してください。<br><a href="/account/password">パスワードを設定する</a>';
                vex.dialog.alert({
                    unsafeMessage: $message,
                });
            }
            
            return false;
        });

        /**
         * バックスペース押下時
         */
        $(document).on('keydown', 'body', function(e) {
            // リモーダルを閉じる
            if(e.keyCode === 8 && !$(':input:focus').length && $('.remodal').remodal().state == 'opened') {
                $('.remodal').remodal().close();
                return false;
            }
        });

        /**
         * アフィリエイトイベントをGoogle Analyticsに送信
         */
        $('.affiliate_event').click(function () {
            var action = $(this).attr('data-action');
            var label = $(this).attr('data-label');
            ga('send', 'event', 'affiliate', action, label);
        });

        /**
         * キャンペーンイベントをGoogle Analyticsに送信
         */
        $('.campaign_event').click(function () {
            var action = $(this).attr('data-action');
            var label = $(this).attr('data-label');
            ga('send', 'event', 'campaign', action, label);
        });

        /**
         * 効果測定用イベントをGoogle Analyticsに送信
         */
        $('.measure_event').click(function () {
            var action = $(this).attr('data-action');
            var label = $(this).attr('data-label');
            ga('send', 'event', 'measure', action, label);
        });

        window.wdCommonLoaded = true;
    });
}).call(this);

/**
 * 共通処理クラス
 */
(function () {
    this.Common = (function () {
        function Common() {
        }

        /**
         * 共通のajaxフォーム送信処理（confirm付き）
         * @param $form フォームのjQueryオブジェクト
         * @param message 確認メッセージ
         * @param callbacks ajax実行後のコールバックを指定する場合は使用（コールバックの詳細はajaxSubmit()を参照）
         */
        Common.prototype.ajaxConfirmFormSubmit = function ($form, message, callbacks) {
            var that = this;

            vex.dialog.confirm({
                message: message,
                callback: function (value) {
                    if (value) {
                        that.ajaxFormSubmit($form, callbacks);
                    }
                }
            });
        };

        /**
         * 共通のajaxフォーム送信処理
         * @param $form フォームのjQueryオブジェクト
         * @param callbacks ajax実行後のコールバックを指定する場合は使用（コールバックの詳細はajaxSubmit()を参照）
         */
        Common.prototype.ajaxFormSubmit = function ($form, callbacks) {
            var that = this;

            if (callbacks === undefined) {
                callbacks = {};
            }
            if (callbacks.error === undefined) {
                callbacks.error = function(data, textStatus, xhr) {
                    that.show_error_msg($form, data.errors);
                };
            }
            if (callbacks.fail === undefined) {
                callbacks.fail = function(message) {
                    that.show_error_msg($form, [message]);
                };
            }

            this.ajaxSubmit($form.attr('action'), $form.attr('method'), $form.serialize(), callbacks);
        };
        /**
         * 共通のajaxリクエスト処理
         * @param url 対象のURL
         * @param method get|post
         * @param send_data 送信データ
         * @param callbacks ajax実行後のコールバックを指定する場合は使用
         * {
         *     // 処理成功時に呼ばれる処理
         *     success: function(data, textStatus, xhr) {
         *         // some logic
         *     },
         *     // 通信には成功しているがサーバーからエラー情報が返ってきたときに呼ばれる処理
         *     error: function(data, textStatus, xhr) {
         *         // some logic
         *     },
         *     // 通信に失敗、もしくはHTTPエラーで返ってきたときに呼ばれる処理
         *     fail: function(message) {
         *         // some logic
         *     }
         * }
         */
        Common.prototype.ajaxSubmit = function (url, method, send_data, callbacks) {
            if (callbacks === undefined) {
                callbacks = {};
            }
            $.ajax(url, {
                type: method,
                data: send_data,
                dataType: 'json',
                cache: false,
                timeout: 30000, // 30秒
                beforeSend: function () {
                    $.blockUI();
                }
            }).done(function (data, textStatus, xhr) {
                if  (data.status === 'OK') {
                    if (callbacks.success) {
                        // コールバックの指定があればそちらを呼び出す
                        callbacks.success(data, textStatus, xhr);
                    } else {
                        // リロード
                        location.href = data.href ? data.href : location.href;
                    }
                } else {
                    if (data.errors) {
                        if (callbacks.error) {
                            // コールバックの指定があればそちらを呼び出す
                            callbacks.error(data, textStatus, xhr);
                        } else {
                            // エラー出力
                            data.errors.forEach(function (error) {
                                swalToast(error, {icon: 'warning'});
                            });
                        }
                    } else {
                        if (callbacks.fail) {
                            // コールバックの指定があればそちらを呼び出す
                            callbacks.fail('ネットワークエラー');
                        } else {
                            // エラー出力
                            swalToast('ネットワークエラー', {icon: 'warning'});
                        }
                    }
                    // パスワード入力欄クリア
                    $('[type=password]').val('');
                }
            }).fail(function (xhr, textStatus, errorThrown) {
                if (xhr.status === 401) {
                    // ログインエラーの場合は、セッションタイムアウトとして処理
                    this.session_timeout();
                } else {
                    if (callbacks.fail) {
                        // コールバックの指定があればそちらを呼び出す
                        callbacks.fail('ネットワークエラー');
                    } else {
                        // エラー出力
                        swalToast('ネットワークエラー', {icon: 'warning'});
                    }
                }
            }).always(function () {
                $.unblockUI();
            });
        };

        /**
         * エラー出力
         * @param $form
         * @param messages
         */
        Common.prototype.show_error_msg = function ($form, messages) {
            $(window).scrollTop(0); // エラー文言の位置にスクロールを合わせる
            $form.find('.error_message').empty();
            Object.keys(messages).forEach(function (key) {
                $form.find('.error_message').append(messages[key] + '<br>');
            });
            $form.find('.error_message').addClass('errors');
        };

        /**
         * セッションタイムアウトによるリダイレクト
         */
        Common.prototype.session_timeout = function () {
            // 全てのリモーダルを閉じる
            $('.remodal').each(function () {
                $(this).remodal().close();
            });
            // アラート表示
            vex.dialog.alert({
                message: 'セッションが切れました。再度ログインしてください。',
                callback: function (value) {
                    // リロードしてログイン画面表示
                    location.reload(true);
                }
            });
        };

        /**
         * 数値をカンマ区切りにして返す
         * @param num
         * @return {string}
         */
        Common.prototype.number_format = function (num) {
            return num.toString().replace(/(\d)(?=(\d{3})+$)/g , '$1,');
        };

        return Common;
    })();
}).call(this);
