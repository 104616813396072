/**
 * 認証メール管理画面のJS
 */
(function () {
    this.ManageMailCertification = (function () {
        function ManageMailCertification() {
            this.setListener();
        }

        ManageMailCertification.prototype.setListener = function () {

            var common = new Common();
            $(document).on('click', '[data-action="submit_mail_certification"]', function () {
                common.ajaxFormSubmit($(this).closest('form'),{
                    success: function(data, textStatus, xhr) {
                        location.href='/manage_mail_certification/confirm';
                    }
                });
            });
        };

        return ManageMailCertification;
    })();
}).call(this);