/**
 * お礼状のデザイン管理クラス
 */
(function () {
    this.ThanksLetterDesign = (function () {
        function ThanksLetterDesign() {
            this.init();
        }

        ThanksLetterDesign.prototype.init = function () {
            /**
             * Vueオブジェクト
             * @type {Vue}
             */
            var app = new Vue({
                el: '[data-view="thanks_letter_design"]',
                /**
                 * ミックスインオブジェクト
                 */
                mixins: [
                    CommonMixin,
                ],
                /**
                 * コンポーネント群
                 */
                components: {
                    'design-modal': this.designModal(),
                },
                /**
                 * データ群
                 */
                data: function () {
                    return {
                        invitation: {}, // 選択中の招待状
                        designTemplates: {}, // デザインテンプレート一覧
                        selectedDesign: {}, // 選択中のデザイン
                        allDesigns: {}, // 全てのデザイン一覧
                        designModalData: {}, // モーダルで表示しているデザインの情報
                        designModalListName: '', // モーダルで表示しているデザインの一覧名
                    }
                },
                /**
                 * インスタンス生成時に実行される処理
                 */
                created: function () {
                    // 各データをjsonから取得
                    this.invitation = JSON.parse(document.querySelector('[data-role="invitation_json"]').innerHTML);
                    this.designTemplates = JSON.parse(document.querySelector('[data-role="design_templates_json"]').innerHTML);

                    if( this.designTemplates[this.invitation['template']] ) {
                        this.selectedDesign = this.designTemplates[this.invitation['template']];
                    }
                },
                /**
                 * 画面描画後に実行される処理
                 */
                mounted: function () {
                    var _this = this;

                    // 各種デザイン一覧を生成
                    var designTemplateKeys = Object.keys(this.designTemplates);
                    for (var i = 0; i < designTemplateKeys.length; i++) {
                        var key = designTemplateKeys[i];
                        var design = this.designTemplates[key];
                        if (design.deleted) {
                            continue;
                        }

                        Vue.set(this.allDesigns, key, design);
                    }

                    // モーダルを閉じた場合にはオブジェクトを空にする
                    $(document).on('closing', '.remodal.design_modal', function (e) {
                        _this.designModalData = {};
                    });
                },
                /**
                 * 各種メソッド
                 */
                methods: {
                    /**
                     * デザイン選択モーダルを表示する
                     * @param template
                     * @param listName
                     */
                    openDesignModal: function (template, listName) {
                        this.designModalListName = listName;
                        this.changeModalDesign(template);
                        this.$refs.designModal.open();
                    },

                    /**
                     * モーダル内でデザイン切り替え処理が呼ばれた際に実行されるコールバック
                     * @param action
                     */
                    onChangeDesign: function (action) {
                        var oldTemplate = this.designModalData.key;
                        var listKeys = Object.keys(this[this.designModalListName]);
                        var index = 0;
                        for (var i = 0; i < listKeys.length; i++) {
                            if (listKeys[i] === oldTemplate) {
                                index = i;
                                break;
                            }
                        }
                        var newTemplate;
                        switch (action) {
                            case 'next':
                                newTemplate = listKeys[index + 1];
                                break;
                            case 'prev':
                                newTemplate = listKeys[index - 1];
                                break;
                            default:
                                console.error('invalid action.');
                        }

                        this.changeModalDesign(newTemplate);
                    },

                    /**
                     * モーダル内のデザインを切り替える
                     * @param template
                     */
                    changeModalDesign: function (template) {
                        // 前/次の判定用に一覧チェック
                        var hasNext = false;
                        var hasPrev = false;
                        if (this.designModalListName) {
                            var listKeys = Object.keys(this[this.designModalListName]);
                            if (listKeys[0] === template) {
                                // 先頭の場合
                                hasNext = true;
                                hasPrev = false;
                            } else if (listKeys[listKeys.length - 1] === template) {
                                // 最後の場合
                                hasNext = false;
                                hasPrev = true;
                            } else {
                                hasNext = true;
                                hasPrev = true;
                            }
                        }

                        var design = this.designTemplates[template];
                        design.key = template;
                        design.hasNext = hasNext;
                        design.hasPrev = hasPrev;
                        this.designModalData = design;
                    },
                }
            });
        };

        /**
         * デザイン選択モーダルのVueコンポーネント
         */
        ThanksLetterDesign.prototype.designModal = function () {
            return {
                /**
                 * テンプレート
                 */
                template: '#thanks-letter-design-modal-template',

                /**
                 * ミックスインオブジェクト
                 */
                mixins: [
                    CommonMixin,
                ],
                /**
                 * プロパティ群
                 */
                props: {
                    // モーダルに表示するデザイン
                    design: {
                        type: Object,
                        required: true,
                    },
                    // 現在招待状に選択中のデザイン
                    selectedDesign: {
                        type: Object,
                        required: true,
                    },
                },
                /**
                 * データ群
                 */
                data: function () {
                    return {
                        previewLoaded: false, // プレビューのロード終了フラグ
                    }
                },
                /**
                 * ウォッチャー
                 */
                watch: {
                    // テンプレートが変更されたら読み込み終了までフラグOFF
                    'design.key': function () {
                        if (!this.design.key) {
                            return;
                        }
                        var _this = this;
                        _this.previewLoaded = false;
                        // VueのDOM更新は非同期のため、DOMの更新が終わってからDOM読み込み
                        Vue.nextTick(function () {
                            _this.$el.querySelector('iframe').onload = function() {
                                _this.previewLoaded = true;
                            }
                        });
                    },
                },

                /**
                 * 各種メソッド
                 */
                methods: {
                    open: function () {
                        $(this.$el).remodal({hashTracking: false}).open();
                    },

                    /**
                     * formがsubmitした際に呼ばれる。
                     * UPDATE処理を実行
                     * @param e
                     */
                    onSubmit: function (e) {
                        var _this = this;
                        var form = e.currentTarget;
                        var isDesignDeleted = _this.selectedDesign.deleted; // 選択中のデザインがすでに削除済みステータスのものか

                        // VueのDOM更新は非同期のため、DOMの更新が全て終わってからフォーム処理を行う
                        Vue.nextTick(function () {
                            var submitForm = function() {
                                $.blockUI();
                                form.submit();
                            };
                            // 削除済みのテンプレートの場合は確認してから変更
                            if (isDesignDeleted) {
                                vex.dialog.confirm({
                                    message: '現在ご利用中のデザインは旧デザインのため、変更後は元に戻すことができません。変更してもよろしいですか？',
                                    callback: function (value) {
                                        if (value) {
                                            submitForm();
                                        }
                                    }
                                });
                            } else {
                                submitForm();
                            }
                        });
                    },
                }
            };
        };

        return ThanksLetterDesign;
    })();
}).call(this);
