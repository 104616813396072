/**
 * Weddingday PhotoのJS
 */
(function () {
    this.WithPhoto = (function () {
        function WithPhoto() {
            this.setListener();
        }

        WithPhoto.prototype.setListener = function () {

            // 2ヶ月前から1週間前までの期間のみ可能にする
            jQuery.validator.addMethod("dateCheck", function(value, element) {
                $_now = new Date();
                $now = new Date($_now.getFullYear(), $_now.getMonth(), $_now.getDate(), 0, 0, 0);
                $contactDateBefore = new Date(value);
                $contactDateAfter = new Date(value);
                $contactDateBefore.setMonth($contactDateBefore.getMonth() - 2);
                $contactDateAfter.setDate($contactDateAfter.getDate() - 7);
                return $contactDateBefore.getTime() < $now.getTime() && $now.getTime() <= $contactDateAfter.getTime();
            }, 'お申し込みは開催日の2ヶ月前から1週間前までの期間のみ可能です。');



            $form = $(".form_validate");
            $form_name = $("input[name=form_name]").val();
            $form.validate({
                rules: {
                    groom_name: {
                        required: true
                    },
                    bride_name: {
                        required: true
                    },
                    mail: {
                        required: true,
                        email: true
                    },
                    tel: {
                        required: true
                    },
                    date: {
                        required: true,
                        dateCheck: true
                    },
                    time_1: {
                        required: true
                    },
                    time_2: {
                        required: true
                    },
                    time_3: {
                        required: true
                    },
                    place: {
                        required: true
                    },
                    place_address: {
                        required: true
                    },
                },
                //エラーメッセージの設定
                messages: {
                    groom_name: {
                        required: '必須項目です'
                    },
                    bride_name: {
                        required: '必須項目です'
                    },
                    mail: {
                        required: '必須項目です',
                        email: 'メールアドレスの形式ではありません。'
                    },
                    tel: {
                        required: '必須項目です'
                    },
                    date: {
                        required: '必須項目です'
                    },
                    time_1: {
                        required: '必須項目です'
                    },
                    time_2: {
                        required: '必須項目です'
                    },
                    time_3: {
                        required: '必須項目です'
                    },
                    place: {
                        required: '必須項目です'
                    },
                    place_address: {
                        required: '必須項目です'
                    },
                },
                submitHandler: function() {
                    ga('send','event','send_form',$form_name);
                    return true;
                }
            });
        };
        return WithPhoto;
    })();
}).call(this);