/**
 * ゲストへ送る画面のJS
 */
(function () {
    this.ManageShare = (function () {
        function ManageShare() {
            this.setListener();
        }

        ManageShare.prototype.setListener = function () {
            // URLコピー
            if (Clipboard.isSupported()) {
                var clipboard = new Clipboard('[data-action="invitation_url_copy"]');
                clipboard.on('success', function() {
                    swalToast('招待状のURLをコピーしました', {icon: 'success'});
                });
            } else {
                $('[data-action=invitation_url_copy]').hide();
            }

            // メッセージコピー
            if (Clipboard.isSupported()) {
                var clipboard = new Clipboard('[data-action="share_message_copy"]');
                clipboard.on('success', function() {
                    swalToast('メッセージをコピーしました', {icon: 'success'});
                });
            } else {
                $('[data-action=share_message_copy]').hide();
            }
            $("a.choice_example_text").click(function(){
                var exampleText = $(this).prev().text().replace('<br>', '\n');
                $('textarea.share_text').val(exampleText);
                $('.example_text_modal').remodal().close();
                share_text_copy();
            });
            share_text_copy();
            function share_text_copy(){
                var shareText = $('textarea.share_text').val();
                $('a[data-action="share_message_copy"]').attr('data-clipboard-text',shareText);
            }
            $('textarea.share_text').on('keyup input', function() {
                share_text_copy();
            });

            // 文例を選択
            $('.message_pattern select').change(function () {
                var message_pattern_key = $('[name="message_pattern"]').val();
                var message_pattern_val = $('.message_pattern').find('label').eq(message_pattern_key).html();
                $('textarea.message_textarea').val(message_pattern_val);
            });

            // LINEで送る
            $('[data-action="send_line"]').click(function () {
                if($('[name="line_message"]').val().length > 700){
                    $("p.error").html('文字数の上限を超えています。');
                }else{
                    window.open('http://line.me/R/msg/text/?' + encodeURIComponent($('[name="line_message"]').val()), '_blank');
                }
            });

            // MAILで送る
            $('[data-action="mail"]').click(function () {
                var title = '';
                var body = encodeURIComponent($('[name="mail_message"]').val());
                window.location.href = 'mailto:?subject=' + title + '&body=' + body;
            });
        };

        return ManageShare;
    })();
}).call(this);
