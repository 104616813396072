
$(window).on('load', function() {

    // ウィザードの際はアイコンを表示
    if ($('.sub_header').length && $('.wizard').length) {
        var startPos = 0,winScrollTop = 0;
        var header = $('.manage_header');
        var subHeader = $('.sub_header');

        $(window).scroll(function () {
            winScrollTop = $(window).scrollTop();
            if (winScrollTop >= header.height()) {//スクロール位置がヘッダー以降で発生

                if (winScrollTop >= startPos) { //下方スクロール
                    subHeader.removeClass('down');
                } else { //上方スクロール
                    subHeader.addClass('down');
                }
                startPos = winScrollTop;
            }
        });
    }

    // キャンペーンバナー
    if ($('.campaign').length) { //キャンペーンバナーが存在しない場合は何もしない。
        var header = $('.manage_header');
        var campaign = $('.campaign');
        header.css('transition', 'transform 0s');

        campaign_scroll();
        $(window).scroll(function () {
            campaign_scroll();
        });
        // バナー位置の処理
        function campaign_scroll() {
            winScrollTop = $(window).scrollTop();
            if (winScrollTop < campaign.height()) {//スクロール位置がヘッダー以降で発生
                header.css('position', 'absolute');
                header.css('transform', 'translateY(' + campaign.height() + 'px)');
                $('.menu').css('transform', 'translateY(0px)');
            } else {
                header.css('position', 'fixed');
                header.css('transform', 'translateY(0px)')
            }
        }

        //メニューを展開した際の処理
        $('.drawer_button').on('click',function(){
            if (header.css('position') === 'fixed') {
                campaign.css('margin-top',- campaign.height());
            }else{
                campaign.css('margin-top',0);
            }
        });
    }
});
