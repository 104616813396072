/**
 * 振込申請クラス
 */
(function () {


    this.ManageDraw = (function () {
        function ManageDraw() {
            this.setListener();
        }



        // アップロード画像の最大は15M
        var FILE_LIMIT_SIZE = 15 * 1024 * 1024;

        var common = new Common();

        // 決済がない場合input入力不可
        ManageDraw.prototype.input_disabled = function() {
            $('#manage_container input').prop('disabled',true);
            $('#manage_container select').prop('disabled',true);
            $('.btn_photo_change, .btn_bank_search').addClass('anchor_disabled');
        }

        ManageDraw.prototype.setListener = function () {

            /**
             * 振込申請履歴の詳細表示処理
             */
            $(document).on('click', '[data-action="show"]', function () {
                $(this).siblings('.detail').slideToggle();
            });

            /**
             * 振込申請詳細の却下された申請を展開
             */
            if (document.querySelector("[data-event=opend]") !== null){
                var denial_draw = document.querySelectorAll("[data-event=opend]");
                for (var i = 0; i < denial_draw.length; i++) {
                    $(denial_draw[i]).siblings('.detail').slideToggle();
                }
            }

            /**
             * 本人確認書類一覧のモーダル表示
             */
            $(document).on('click', '[data-action="open_verification_modal"]', function () {
                $('[data-remodal-id="verification_modal"]').remodal({
                    hashTracking: false,
                }).open();
            });

            /**
             * 本人確認書類一覧のモーダル非表示
             */
            $(document).on('click', '[data-action="close_verification_modal"]', function () {
                $('[data-remodal-id="verification_modal"]').remodal().close();
            });

            /**
             * 入力欄の表示処理
             */
            $(document).on('click', '[data-action="show_input_area"]', function () {
                $('#last_draw_request_area').slideUp();
                // 本人確認書類は空にする
                $('#input_area input[name="id_photo_url_1"]').val('');
                $('#input_area input[name="id_photo_url_2"]').val('');
                $('#input_area').slideDown();
            });


            /**
             * 氏名(カナ)の入力により、口座名義も自動入力
             */
            $('[name=user_name_kana]').on('keyup input blur',function () {
                $('[name=account_name]').val($(this).val());
            });

            /**
             * 金融機関検索リモーダル表示
             */
            $('[data-action="bank_modal"]').click((function () {
                var template = Handlebars.compile($('#bank_modal_template').html());
                $('[data-remodal-id="bank_modal"]').html(template({}));
                $('[data-remodal-id="bank_modal"]').remodal({hashTracking: false}).open();
                // 初期検索実行
                $('[data-action="search_bank"]').click();
            }));

            /**
             * 金融機関検索
             */
            $(document).on('click', '[data-action="search_bank"]', function () {
                // 検索処理後コールバック処理定義
                var form = $(this).closest('form');
                var callback = function(result) {
                    form.find('.search_result li').remove();
                    Object.keys(result).forEach(function (key) {
                        var row = result[key];
                        form.find('.search_result').append(
                            '<li><a href="javascript:void(0)" ' +
                            '       data-action="choose_bank" ' +
                            '       data-bank_code="'+row['bank_code']+'" ' +
                            '       data-bank_name="'+row['bank_name']+'" ' +
                            '       data-bank_name_kana="'+row['bank_name_kana']+'">' +
                            '<div class="name">' + row['bank_name'] + '</div><div class="code">（' + row['bank_code'] + '）</div>' +
                            '</a></li>'
                        );
                    });
                };
                // 検索実行
                ajax_search(form, callback);
            });

            /**
             * 金融機関選択時
             */
            $(document).on('click', '[data-action=choose_bank]', function () {
                // エラー文言をクリア
                hide_error_msg($(this).closest('form'));
                // 金融機関選択フォームから支店選択フォームに切り替える
                $('.search_bank').hide();
                $('.search_branch').show();
                // 支店選択フォームに金融機関情報を渡す
                $('.search_branch [name=bank_code]').val($(this).data('bank_code'));
                $('.search_branch [name=bank_name]').val($(this).data('bank_name'));
                $('.search_branch [name=bank_name_kana]').val($(this).data('bank_name_kana'));
                $('.search_branch .selected label[name=bank_code]').text($(this).data('bank_code'));
                $('.search_branch .selected label[name=bank_name]').text($(this).data('bank_name'));
                // 手動検索フォームにも情報を渡す
                $('.manual_bank_input [name=bank_code]').val($(this).data('bank_code'));
                $('.manual_bank_input [name=bank_name]').val($(this).data('bank_name'));
                $('.manual_bank_input [name=bank_name_kana]').val($(this).data('bank_name_kana'));

                // 初期検索実行
                $('[data-action="search_bank_branch"]').click();
            });

            /**
             * 支店検索
             */
            $(document).on('click', '[data-action="search_bank_branch"]', function () {
                // 検索処理後コールバック処理定義
                var form = $(this).closest('form');
                var callback = function(result) {
                    form.find('.search_result li').remove();
                    Object.keys(result).forEach(function (key) {
                        var row = result[key];
                        form.find('.search_result').append(
                            '<li><a href="javascript:void(0)" ' +
                            '       data-action="choose_branch" ' +
                            '       data-branch_code="'+row['branch_code']+'" ' +
                            '       data-branch_name="'+row['branch_name']+'" ' +
                            '       data-branch_name_kana="'+row['branch_name_kana']+'">' +
                            '<div class="name">' + row['branch_name'] + '</div><div class="code">（' + row['branch_code'] + '）</div>' +
                            '</a></li>'
                        );
                    });
                };
                // 検索実行
                ajax_search(form, callback);
            });

            /**
             * 支店選択時
             */
            $(document).on('click', '[data-action=choose_branch]', function () {
                // 支店選択フォームから確認フォームに切り替える
                $('.search_branch').hide();
                $('.search_confirm').show();
                // 元画面に金融機関情報をhiddenパラメーターにセット
                var form = $(this).closest('form');
                $('.search_confirm [name=bank_code]').val(form.find('[name=bank_code]').val());
                $('.search_confirm [name=bank_name]').val(form.find('[name=bank_name]').val());
                $('.search_confirm [name=bank_name_kana]').val(form.find('[name=bank_name_kana]').val());
                $('.search_confirm [name=branch_code]').val($(this).data('branch_code'));
                $('.search_confirm [name=branch_name]').val($(this).data('branch_name'));
                $('.search_confirm [name=branch_name_kana]').val($(this).data('branch_name_kana'));
                $('.search_confirm .selected label[name=bank_code]').text(form.find('[name=bank_code]').val());
                $('.search_confirm .selected label[name=bank_name]').text(form.find('[name=bank_name]').val());
                $('.search_confirm .selected label[name=branch_code]').text($(this).data('branch_code'));
                $('.search_confirm .selected label[name=branch_name]').text($(this).data('branch_name'));
                // 手動検索フォームにも情報を渡す
                $('.manual_bank_input [name=branch_code]').val($(this).data('branch_code'));
                $('.manual_bank_input [name=branch_name]').val($(this).data('branch_name'));
                $('.manual_bank_input [name=branch_name_kana]').val($(this).data('branch_name_kana'));
            });

            /**
             * 支店選択から金融機関選択フォームに戻す
             */
            $(document).on('click', '[data-action=back_to_bank]', function () {
                // 支店選択から金融機関選択フォームに戻す
                $('.search_branch').hide();
                $('.search_bank').show();
                // 支店選択フォームの初期化
                var form = $(this).closest('form');
                hide_error_msg(form);
                form.find('.search_result li').remove();
                form.find('[name=search]').val('');
                form.find('[name=bank_code]').val('');
                form.find('[name=bank_name]').val('');
                form.find('[name=bank_name_kana]').val('');
            });

            /**
             * 金融機関と支店確定
             */
            $(document).on('click', '.search_confirm [data-action=confirm]', function () {
                var form = $(this).closest('.search_confirm');
                // 元画面に金融機関名と支店名表示
                $('.bank_and_branch_name').html(form.find('[name=bank_name]').val() + ' / ' + form.find('[name=branch_name]').val()+'　');
                // 元画面に金融機関情報をhiddenパラメーターにセット
                $('[name=bank_code]').val(form.find('[name=bank_code]').val());
                $('[name=bank_name]').val(form.find('[name=bank_name]').val());
                $('[name=bank_name_kana]').val(form.find('[name=bank_name_kana]').val());
                $('[name=branch_code]').val(form.find('[name=branch_code]').val());
                $('[name=branch_name]').val(form.find('[name=branch_name]').val());
                $('[name=branch_name_kana]').val(form.find('[name=branch_name_kana]').val());
                // リモーダル閉じる
                $('[data-remodal-id="bank_modal"]').remodal().close();
                // ボタンの色変更
                $('.btn_bank_search').addClass('selected');
            });

            /**
             * 金融機関と支店の手動入力確定
             */
            $(document).on('click', '[data-action=manual_submit]', function () {
                var $wrapper = $(this).closest('.manual_bank_input');
                hide_error_msg($wrapper);

                var errors = [];
                if (!$wrapper.find('[name=bank_code]').val()) {
                    errors.push('銀行コードを入力してください');
                }
                if (!$wrapper.find('[name=bank_name]').val()) {
                    errors.push('銀行名を入力してください');
                }
                if (!$wrapper.find('[name=branch_code]').val()) {
                    errors.push('支店コードを入力してください');
                }
                if (!$wrapper.find('[name=branch_name]').val()) {
                    errors.push('支店名を入力してください');
                }
                if (!$wrapper.find('[name=branch_name_kana]').val()) {
                    errors.push('支店名(カナ)を入力してください');
                }
                if (errors.length > 0) {
                    show_error_msg($wrapper, errors);
                    return;
                }

                // 元画面に金融機関名と支店名表示
                $('.bank_and_branch_name').html($wrapper.find('[name=bank_name]').val() + ' / ' + $wrapper.find('[name=branch_name]').val()+'　');
                // 元画面に金融機関情報をhiddenパラメーターにセット
                $('[name=bank_code]').val($wrapper.find('[name=bank_code]').val());
                $('[name=bank_name]').val($wrapper.find('[name=bank_name]').val());
                $('[name=bank_name_kana]').val($wrapper.find('[name=bank_name_kana]').val());
                $('[name=branch_code]').val($wrapper.find('[name=branch_code]').val());
                $('[name=branch_name]').val($wrapper.find('[name=branch_name]').val());
                $('[name=branch_name_kana]').val($wrapper.find('[name=branch_name_kana]').val());
                // リモーダル閉じる
                $('[data-remodal-id="bank_modal"]').remodal().close();
                // ボタンの色変更
                $('.btn_bank_search').addClass('selected');
            });

            /**
             * 確認フォームから支店選択フォームに戻す
             */
            $(document).on('click', '[data-action=back_to_branch]', function () {
                // 確認フォームから支店選択フォームに戻す
                $('.search_confirm').hide();
                $('.search_branch').show();
                // 確認フォームの初期化
                var form = $(this).closest('.search_confirm');
                form.find('[type=hidden]').val('');
            });

            /**
             * ajaxによる検索処理
             */
            function ajax_search(form, callback) {
                // エラー非表示
                hide_error_msg(form);
                // 検索結果一覧初期化
                form.find('.search_result li').remove();
                common.ajaxFormSubmit(form, {
                    success: function (data, textStatus, xhr) {
                        if (!data.result.length) {
                            show_error_msg(form, ['検索にヒットしませんでした。検索条件を確認してください。']);
                        } else if (data.result.length >= 100) {
                            show_error_msg(form, ['100件以上見つかりました。先頭の100件のみ表示します。']);
                            // 検索ヒット時はコールバック関数実行
                            callback(data.result);
                        } else {
                            // 検索ヒット時はコールバック関数実行
                            callback(data.result);
                        }
                    }
                });
            }

            /**
             * エラー表示
             */
            function show_error_msg(form, msg) {
                form.find('.error_message ').empty();
                form.find('.error_message').addClass('errors');
                for(key in msg) {
                    form.find('.error_message ').append(msg[key] + '<br>');
                }
            }

            /**
             * エラー非表示
             */
            function hide_error_msg(form) {
                form.find('.error_message ').empty();
                form.find('.error_message').removeClass('errors');
            }

            /**
             * 本人確認書類を選択する押下
             */
            $('[data-action="photo_change"]').click(function (e) {
                $(this).siblings('input[type=file]').click();
            });

            /**
             * ファイル選択直後
             */
            $('input[type=file]').change(function () {
                $(this).siblings('div').remove();
                $(this).siblings('a').removeClass('selected');

                // ファイルチェック
                var file = $(this).prop('files')[0];
                if (!file) {
                    // ファイルが選択されていない場合はスルー
                    return;
                }
                var acceptFileTypes = /^image\/(gif|jpe?g|png)$/i;
                var dataType = file.type;

                if (!!dataType && (dataType.length === 0 || !acceptFileTypes.test(dataType))) {
                    $(this).val(''); // inputタグのファイルデータクリア
                    return vex.dialog.alert('対応していないファイル形式です。jpeg/gif/pngのいずれを指定してください。');
                }

                var fileSizeTotal = 0;
                var file1 = $('input[type="file"][name="photo_1"]').prop('files')[0];
                var file2 = $('input[type="file"][name="photo_2"]').prop('files')[0];
                if (file1 && file1.size) {
                    fileSizeTotal += Number(file1.size);
                }
                if (file2 && file2.size) {
                    fileSizeTotal += Number(file2.size);
                }
                if (fileSizeTotal > FILE_LIMIT_SIZE) {
                    $(this).val(''); // inputタグのファイルデータクリア
                    return vex.dialog.alert('ファイルサイズが大きすぎます。合計で15MB以下のファイルを指定してください。');
                }

                // 選択済みマーク表示
                $(this).siblings('a.btn_photo_change').before(
                    '<div class="file_name">選択済み(' + file.name + ')'+
                    '   <a href="javascript:void(0)" data-action="delete_file_data">' +
                    '       <span class="icon"><i class="icon ion-close-circled"></i></span>' +
                    '   </a>' +
                    '</div>'
                );
                $(this).siblings('a').addClass('selected');
            });

            /**
             * 申請確定時
             */
            $('[data-action="draw_ajax_submit"]').click(function () {
                var form = $(this).closest('form');

                // 本人確認書類選択済チェック
                var personal_file_selected = false;
                $('input[type=file], [name^=id_photo_url_]').each(function(){
                    if($(this).val()) {
                        personal_file_selected = true;
                    }
                });
                if( !personal_file_selected) {
                    vex.dialog.alert("本人確認書類が選択されていません");
                    return false;
                }
                // 本人確認書類が2枚目だけ選択されているときはユーザーの選択ミスと判断し、1枚目の選択も促す
                if ($('input[type=file][name=photo_2]').val() && !$('input[type=file][name=photo_1]').val()) {
                    vex.dialog.alert('本人確認書類(1)を選択してください');
                    return false;
                }

                vex.dialog.confirm({
                    message: '振込申請は取り消すことができません。振込申請を行いますか？',
                    callback: function (value) {
                        if (value) {
                            $.ajax(form.attr('action'), {
                                type: form.attr('method'),
                                data: new FormData(form.get()[0]),
                                dataType: 'json',
                                cache: false,
                                timeout: 120000, // 120秒
                                processData: false,
                                contentType: false,
                                beforeSend: function () {
                                    $.blockUI({
                                        message: '<div class="progress_bar_wrapper"><div id="progress_bar" class="progress_bar" style="width: 0%;">0%</div></div>',
                                    });
                                },
                                xhr : function(){
                                    var xhr = $.ajaxSettings.xhr();
                                    xhr.upload.onprogress = function (e) {
                                        if (e.lengthComputable) {
                                            var progress = Math.ceil(e.loaded / e.total * 100);
                                            $('#progress_bar').css('width', progress + '%').text(progress + '%');
                                            if (Number(progress) === 100) {
                                                $('#progress_bar').text('申請処理中...');
                                            }
                                        }
                                    };
                                    return xhr;
                                }
                            }).done(function (data, textStatus, xhr) {
                                if (data.status === 'OK') {
                                    // 完了画面に遷移
                                    location.href = data.href;
                                } else {
                                    // 入力エラー出力
                                    show_error_msg(form, data.errors);
                                    $(window).scrollTop(0);
                                    // バリデーションエラー後、再度同じファイルはアップロードさせない
                                    $('input[type=file]').val('');
                                    // アップロードしたファイルのパスで更新
                                    if (data.urls) {
                                        Object.keys(data.urls).forEach(function (key) {
                                            $('[name='+key+']').val(data.urls[key]);
                                        });
                                    }
                                }
                            }).fail(function (xhr, textStatus, errorThrown) {
                                var message = 'ネットワークエラー';
                                if (textStatus === 'timeout') {
                                    message = '通信タイムアウト。通信環境が良い場所、またはWifiネットワークに接続し実行してください。';
                                }
                                show_error_msg(form, [message]);
                                $(window).scrollTop(0);
                            }).always(function () {
                                $.unblockUI();
                            });
                        }
                    }
                });
            });

            // 画面をロードしたときの本人確認画像URLで初期化値を定義
            var id_photo_url = {
                'id_photo_url_1' : $('[name=id_photo_url_1]').val(),
                'id_photo_url_2' : $('[name=id_photo_url_2]').val(),
            };

            /**
             * ファイル削除ボタン押下
             */
            $(document).on('click', '[data-action="delete_file_data"]', function () {
                var target = $(this).parents('.upload_personal');
                // inputタグのファイルデータクリア
                target.find('input[type=file]').val('');
                // ファイルパスを初期化
                var url_target = target.find('input[name^=id_photo_url_]');
                url_target.val(id_photo_url[url_target.attr('name')]);
                // ファイル名表示部分を削除
                target.find('.file_name').remove();
                // 写真選択ボタンを未選択状態にする
                target.find('a').removeClass('selected');
            });

            /**
             * 銀行/支店の手動入力のトグル
             */
            $(document).on('click', '[data-action="toggle_bank_edit"]', function () {
                if ($('[data-show-if-bank-edit]').is(':visible')) {
                    $('[data-show-if-bank-edit]').slideUp();
                } else {
                    $('[data-show-if-bank-edit]').slideDown();
                }
            });
        };

        return ManageDraw;
    })();
}).call(this);