/**
 * ユーザー一斉メール送信クラス
 */
(function () {
    this.ManageBulkMail = (function () {
        function ManageBulkMail() {
            this.common = new Common();
            this.invitation_url = document.querySelector('[data-roll="invitation_url"]').innerHTML;
            this.invitation_title = document.querySelector('[name="invitation_title"]').innerHTML;
            this.invitation_type = document.querySelector('[name="invitation_type"]').innerHTML;
            this.invitation_date = document.querySelector('[name="invitation_date"]').innerHTML;
            this.invitation_time1 = document.querySelector('[name="invitation_time1"]').innerHTML;
            this.invitation_time2 = document.querySelector('[name="invitation_time2"]').innerHTML;
            this.invitation_time3 = document.querySelector('[name="invitation_time3"]').innerHTML;
            this.invitation_time4 = document.querySelector('[name="invitation_time4"]').innerHTML;
            this.invitation_place = document.querySelector('[name="invitation_place"]').innerHTML;

            this.mail_text = '先日よりご案内しておりました結婚パーティーの開催日が近づいてまいりましたので、ご出席いただく皆さまに当日の詳細をお知らせいたします。\n\n';

            if(this.invitation_date.length){
                this.mail_text = this.mail_text+'[ 開催日 ] ' + this.invitation_date + '\n';
            }
            if (this.invitation_type == 0){ //挙式、披露宴
                if(this.invitation_time1.length){
                    this.mail_text = this.mail_text+'[ 挙式の受付 ] ' + this.invitation_time1 + '\n';
                }
                if(this.invitation_time2.length){
                    this.mail_text = this.mail_text+'[ 挙式の開始 ] ' + this.invitation_time2 + '\n';
                }
                if(this.invitation_time3.length){
                    this.mail_text = this.mail_text+'[ 披露宴の受付 ] ' + this.invitation_time3 + '\n';
                }
                if(this.invitation_time4.length){
                    this.mail_text = this.mail_text+'[ 披露宴の開始 ] ' + this.invitation_time4 + '\n';
                }
            } else if(this.invitation_type == 2){ //会費制、２次会
                if(this.invitation_time1.length){
                    this.mail_text = this.mail_text+'[ 受付時間 ] ' + this.invitation_time1 + '\n';
                }
                if(this.invitation_time2.length){
                    this.mail_text = this.mail_text+'[ 開宴時間 ] ' + this.invitation_time2 + '\n';
                }
            }
            if(this.invitation_place.length){
                this.mail_text = this.mail_text+'[ 場所 ] ' + this.invitation_place + '\n';
            }

            this.mail_text = this.mail_text+'詳細は下記Web招待状のURLをご確認ください。\n';
            this.mail_text = this.mail_text+'[ URL ] ' + this.invitation_url + '\n'+
                '\n\n' +
                'どうぞ、お気をつけてお越しください。\n' +
                '皆さまにお会いできることを、楽しみにしております。\n' +
                '\n' +
                '\n\n';

            // メール本文の定型文
            this.mail_template = {};
            this.mail_template['1'] = this.mail_text;

            this.mail_text = 'この度は、わたしたちの結婚パーティーにご出席いただき、ありがとうございました。\n\n' +
                '皆さまの温かい祝福に包まれ、新たなる門出を迎えることができました。\n' +
                'これからは二人で協力して幸せな家庭を築いていきます。\n\n' +
                '引き続き、末長くお付き合いくださいますよう、お願いいたします。';

            this.mail_template['2'] = this.mail_text;

            this.setListener();
        }

        ManageBulkMail.prototype.setListener = function () {
            var that = this;
            /**
             * メール送信ボタン処理
             */
            $('[data-action="send_bulk_mail"]').click(function (e) {
                console.log($('select[name=category] option:selected').attr('data-cnt'));
                if($('select[name=category] option:selected').attr('data-cnt') !== '0') {
                    e.preventDefault();
                    var $form = $('#send_bulk_mail_form');
                    $form.find('.error_message').empty();
                    $form.find('.error_message').removeClass('errors');
                    that.common.ajaxConfirmFormSubmit($form, 'ゲストへ一斉メール送信を行います。よろしいですか？', {
                        success: function (data, textStatus, xhr) {
                            swalToast('メールを送信しました', {icon: 'success'});
                        }
                    });
                }else{
                    swalToast('送信先人数が0人です', {icon: 'warning'});
                }
            });
            /**
             * メール本文を定型文から選択
             */
            var before_change_sample = '0';
            $('[data-action="select_mail_sample"]').change(function (e) {
                var mail_content = $('[data-roll="mail_content"]').val();
                var before_change_template = that.mail_template[Number(before_change_sample)];
                var template = that.mail_template[Number($(e.currentTarget).val())];

                if (!!mail_content && mail_content !== before_change_template) {
                    vex.dialog.confirm({
                        message: '既に設定されているメール本文を上書きします。よろしいですか？',
                        callback: function (value) {
                            if (value === true) {
                                $('[data-roll="mail_content"]').val(template);
                                before_change_sample = $(e.currentTarget).val();
                            } else {
                                $('[data-action="select_mail_sample"]').val(before_change_sample);
                            }
                        }
                    });
                } else {
                    $('[data-roll="mail_content"]').val(template);
                    before_change_sample = $(e.currentTarget).val();
                }
            });
        };

        return ManageBulkMail;
    })();
}).call(this);
