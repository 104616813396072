/**
 * 共通のVue mixin
 */
(function () {
    this.CommonMixin = {
        /**
         * 各種メソッド
         */
        methods: {
            /**
             * 共通的に使う、Formのsubmit処理
             * @param form
             * @param callback object
             */
            $_submitForm: function (form, callback) {
                var that = this;

                if (callback === undefined) {
                    callback = {};
                }
                that.errors = [];

                $.ajax(form.getAttribute('action'), {
                    type: 'POST',
                    data: new FormData(form),
                    dataType: 'json',
                    cache: false,
                    processData: false,
                    contentType: false,
                    beforeSend: function () {
                        $.blockUI();
                    }
                }).done(function (data, textStatus, xhr) {
                    if  (data.status === 'OK') {
                        if (callback.success) {
                            // コールバックの指定があればそちらを呼び出す
                            callback.success(data, textStatus, xhr);
                        } else {
                            // リロード
                            location.href = data.href ? data.href : location.href;
                        }
                    } else {
                        if (data.errors) {
                            if (callback.error) {
                                // コールバックの指定があればそちらを呼び出す
                                callback.error(data, textStatus, xhr);
                            } else {
                                // エラー出力
                                data.errors.forEach(function (error) {
                                    that.errors.push(error);
                                });
                            }
                        } else {
                            if (callback.fail) {
                                // コールバックの指定があればそちらを呼び出す
                                callback.fail('ネットワークエラー');
                            } else {
                                // エラー出力
                                swalToast('ネットワークエラー', {icon: 'warning'});
                            }
                        }
                    }
                }).fail(function (xhr, textStatus, errorThrown) {
                    if (xhr.status === 401) {
                        // ログインエラーの場合は、セッションタイムアウトとして処理
                        that.$_sessionTimeout();
                    } else {
                        if (callback.fail) {
                            // コールバックの指定があればそちらを呼び出す
                            callback.fail('ネットワークエラー');
                        } else {
                            if (xhr.responseJSON) {
                                that.errors = xhr.responseJSON;
                            } else {
                                // エラー出力
                                swalToast('ネットワークエラー', {icon: 'warning'});
                            }
                        }
                    }
                }).always(function () {
                    $.unblockUI();
                });
            },

            /**
             * セッションタイムアウト処理
             */
            $_sessionTimeout: function () {
                // 全てのリモーダルを閉じる
                $('.remodal').each(function () {
                    $(this).remodal().close();
                });
                // アラート表示
                vex.dialog.alert({
                    message: 'セッションが切れました。再度ログインしてください。',
                    callback: function (value) {
                        // リロードしてログイン画面表示
                        location.reload(true);
                    }
                });
            }
        }
    };
}).call(this);
