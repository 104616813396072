/**
 * NovicスナップLP内form
 */
(function () {
    this.NovicSnap = (function () {
        function NovicSnap() {
            this.setListener();
        }

        NovicSnap.prototype.setListener = function () {

            $('.gallery_more').on('click', function() {
                $(".photo").removeClass('hidden');
                $('.gallery_more').css('display','none');
            });

            $('.gallery_modal_link').on('click', function() {
                var photo_num = $(this).attr('data-num');
                $(".gallery_image").html("<img src='/images/novic_snap/"+photo_num+".jpg'>");
            });


            $form = $(".form_validate");
            $form.validate({
                rules: {
                    groom_name: {
                        required: true
                    },
                    bride_name: {
                        required: true
                    },
                    mail: {
                        required: true,
                        email: true
                    },
                    tel: {
                        required: true
                    },
                    date: {
                        required: true
                    },
                    time_2: {
                        required: true
                    },
                    place: {
                        required: true
                    },
                },
                //エラーメッセージの設定
                messages: {
                    groom_name: {
                        required: '必須項目です'
                    },
                    bride_name: {
                        required: '必須項目です'
                    },
                    mail: {
                        required: '必須項目です',
                        email: 'メールアドレスの形式ではありません。'
                    },
                    tel: {
                        required: '必須項目です'
                    },
                    date: {
                        required: '必須項目です'
                    },
                    time_2: {
                        required: '必須項目です'
                    },
                    place: {
                        required: '必須項目です'
                    },
                },
                submitHandler: function() {
                    return true;
                }
            });
        };
        return NovicSnap;
    })();
}).call(this);