/**
 * 出欠一覧画面用のJS
 */
(function () {
    this.ManageList = (function () {
        function ManageList() {
            var _this = this;
            this.entry_modal_template = Handlebars.compile($('#entry_modal_template').html());
            this.user_email = $('script[data-roll="user_email"]').html();
            this.zip_password = $('script[data-roll="zip_password"]').html();
            this.group_code = $('script[data-roll="group_code"]').html();
            this.design_is_old = $('script[data-roll="design_is_old"]').html() === '1';
            this.setListener();
            this.toggleCsvDownload();

            // commonの読み込みを待ってから処理開始
            var timerId = setInterval(function () {
                if (wdCommonLoaded) {
                    clearInterval(timerId);
                    _this.oneWCallback();
                }
            }, 100);
        }

        ManageList.prototype.setListener = function () {

            /**
             * ソート条件変更時の処理
             */
            $('[data-action="sort"]').change(function () {
                $.blockUI();
                location.href = '?sort=' + $(this).val();
            });

            /**
             * ダウンロード方法のトグル処理
             */
            $(document).on('change', '[type="radio"][name="csv_type"]', (function (_this) {
                return function (e) {
                    _this.toggleCsvDownload();
                };
            })(this));

            /**
             * 行クリック時の詳細表示処理
             */
            $(document).on('click', '[data-action="show"]', function () {
                // .entry_detailの表示非表示はclass="active"で制御
                $(this).closest('.entry').find('.entry_detail').toggleClass('active');
            });
            /**
             * 詳細編集時の処理
             */
            $(document).on('click', '[data-action="entry_edit"]', (function (_this) {
                return function () {
                    var $entry = $(this).closest('.entry');
                    var context = {
                        action: 'update',
                        entry_id: $entry.find('[name="entry_id"]').val(),
                        parent_entry_id: $entry.find('[name="parent_entry_id"]').val(),
                        entry_attend_check: ($entry.find('[name="entry"]').val() === 'ご出席') ? 'checked="checked"' : '',
                        entry_absent_check: ($entry.find('[name="entry"]').val() === 'ご欠席') ? 'checked="checked"' : '',
                        entry_online_check: ($entry.find('[name="entry"]').val() === 'オンライン出席') ? 'checked="checked"' : '',
                        sex: $entry.find('[name="sex"]').val(),
                        sex_male_check: ($entry.find('[name="sex"]').val() === '男性') ? 'checked="checked"' : '',
                        sex_female_check: ($entry.find('[name="sex"]').val() === '女性') ? 'checked="checked"' : '',
                        mail: $entry.find('[name="mail"]').val(),
                        tel: $entry.find('[name="tel"]').val(),
                        post_code: $entry.find('[name="post_code"]').val(),
                        free1: $entry.find('[name="free1"]').val(),
                        free2: $entry.find('[name="free2"]').val(),
                        free3: $entry.find('[name="free3"]').val()
                    };
                    // 招待状デザインの新旧で処理分け
                    if (_this.design_is_old) {
                        context.name = $entry.find('[name="name"]').val();
                        context.kana = $entry.find('[name="kana"]').val();
                        context.address = $entry.find('[name="address"]').val();
                    } else {
                        context.guest_type = $entry.find('[name="guest_type"]').val();
                        context.guest_type_groom_check = ($entry.find('[name="guest_type"]').val() === '1') ? 'checked="checked"' : '';
                        context.guest_type_bride_check = ($entry.find('[name="guest_type"]').val() === '2') ? 'checked="checked"' : '';
                        context.last_name = $entry.find('[name="last_name"]').val();
                        context.first_name = $entry.find('[name="first_name"]').val();
                        context.last_name_kana = $entry.find('[name="last_name_kana"]').val();
                        context.first_name_kana = $entry.find('[name="first_name_kana"]').val();
                        context.last_name_latin = $entry.find('[name="last_name_latin"]').val();
                        context.first_name_latin = $entry.find('[name="first_name_latin"]').val();
                        context.address_1 = $entry.find('[name="address_1"]').val();
                        context.address_2 = $entry.find('[name="address_2"]').val();
                        context.free4 = $entry.find('[name="free4"]').val();
                        context.free5 = $entry.find('[name="free5"]').val();
                    }
                    var html = _this.entry_modal_template(context);
                    $('[data-remodal-id="entry_modal"]').html(html);
                    $('[data-remodal-id="entry_modal"]').remodal({hashTracking: false}).open();
                };
            })(this));
            /**
             * 詳細削除の処理
             */
            $(document).on('click', '[data-action="entry_delete"]', function () {
                var $entry = $(this).closest('.entry');
                if ($entry.find('input[name="entry_paid"]').val() === '1') {
                    vex.dialog.alert({
                        message: 'この回答はご祝儀・会費を事前支払済みのため、削除できません。',
                    });
                    return false;
                }

                var $form = $('#entry_delete_form');
                var entry_id = $(this).closest('.entry').find('input[name="entry_id"]').val();
                vex.dialog.confirm({
                    message: 'この回答を削除します。\n削除した回答を元に戻すことはできません。\n本当によろしいですか？',
                    callback: function (value) {
                        if (value === true) {
                            $form.find('input[name="entry_id"]').val(entry_id);
                            $form.submit();
                        }
                    }
                });
                return false;
            });
            /**
             * 招待客を新規追加
             */
            $('[data-action="add"]').click((function (_this) {
                return function (e) {
                    var context = {
                        action: 'create',
                        entry_attend_check: 'checked="checked"',
                        guest_type_groom_check: 'checked="checked"',
                        sex_male_check: 'checked="checked"'
                    };
                    var html = _this.entry_modal_template(context);
                    $('[data-remodal-id="entry_modal"]').html(html);
                    $('[data-remodal-id="entry_modal"]').remodal({hashTracking: false}).open();
                };
            })(this));

            $('[data-action="show_download_dialog"]').click((function (_this) {
                return function (e) {
                    var message = '取得する方法を選択してください。';
                    var attend = $('.entry[data-entry="attend"]').size();
                    var absent = $('.entry[data-entry="absent"]').size();

                    if (attend === 0 && absent === 0) {
                        swalToast('出欠の回答がありません。', {icon: 'warning'});
                        return false;
                    }
                    if (_this.group_code === 'novic') {
                        var input_array = [
                                '<label><div><input type="radio" name="csv_type" value="1" checked="checked"><span>ダウンロード（全項目）</span></div></label>',
                                '<label><div><input type="radio" name="csv_type" value="3"><span>ダウンロード（受付用）</span></div></label>',
                                '<label><div><input type="radio" name="csv_type" value="2"><span>PCへメール送信（全項目）</span></div></label>',
                                '<label><div><input type="radio" name="csv_type" value="4"><span>PCへメール送信（受付用）</span></div></label>',
                                '<div data-show-if-send-mail="true" style="margin-left: 8px;">',
                                '<div style="margin:5px 0">送信先メールアドレス:<br>',
                                '<input type="email" name="email_address" value="'+_this.user_email+'"></div>',
                                '<div style="margin:5px 0">ZIPパスワード:<br>',
                                '<strong>'+_this.zip_password+'</strong></div>',
                                '※パスワード付きZIPファイルをメール送信します。パスワードは忘れないように管理してください。',
                                '<div data-show-if-has-modal-error="true" class="error_message errors"></div>',
                                '</div>'
                        ];
                    } else {
                        var input_array = [
                                '<label><div><input type="radio" name="csv_type" value="1" checked="checked"><span>ダウンロード</span></div></label>',
                                '<label><div><input type="radio" name="csv_type" value="2"><span>PCへメール送信</span></div></label>',
                                '<div data-show-if-send-mail="true" style="margin-left: 8px;">',
                                '<div style="margin:5px 0">送信先メールアドレス:<br>',
                                '<input type="email" name="email_address" value="'+_this.user_email+'"></div>',
                                '<div style="margin:5px 0">ZIPパスワード:<br>',
                                '<strong>'+_this.zip_password+'</strong></div>',
                                '※パスワード付きZIPファイルをメール送信します。パスワードは忘れないように管理してください。',
                                '<div data-show-if-has-modal-error="true" class="error_message errors"></div>',
                                '</div>'
                        ];
                    }
                    vex.closeAll(); // 連打されたとき用に表示中のダイアログを閉じる
                    vex.dialog.open({
                        unsafeMessage: message,
                        input: input_array.join(''),
                        beforeClose: function() {
                            // キャンセルの場合は何もせずクローズ
                            if (!this.value) {
                                return true;
                            }

                            // 以下OKの場合

                            if (this.value.csv_type === '2' || this.value.csv_type === '4') {
                                // バリデーション
                                $('[data-show-if-has-modal-error="true"]').empty();
                                $('[data-show-if-has-modal-error="true"]').hide();
                                if (!this.value.email_address) {
                                    $('[data-show-if-has-modal-error="true"]').text('メールアドレスを入力してください');
                                    $('[data-show-if-has-modal-error="true"]').show();
                                    return false;
                                }
                            }

                            if (this.value.csv_type === '1' || this.value.csv_type === '3') {
                                // ダウンロード処理
                                if (this.value.csv_type === '1') {
                                    var $element = $('<a download/>').attr({href: '/manage_list/download_csv', style: 'display: none;'});
                                } else {
                                    var $element = $('<a download/>').attr({href: '/manage_list/download_novic_csv', style: 'display: none;'});
                                }

                                $('body').append($element);
                                $element[0].click();
                                $element.remove();
                                return true;
                            } else {
                                // メール送信処理
                                var common = new Common();
                                common.ajaxSubmit('/manage_list/send_csv_mail', 'post', {
                                    password: _this.zip_password,
                                    email_address: this.value.email_address,
                                    csv_type: this.value.csv_type
                                }, {
                                    success: function(data, textStatus, xhr) {
                                        swalToast('メールを送信しました', {icon: 'success'});
                                    }
                                });
                                return true;
                            }
                        },
                    });
                };
            })(this));
        };

        /**
         * ダウンロード方法のトグル処理
         */
        ManageList.prototype.toggleCsvDownload = function () {
            if ($('[type="radio"][name="csv_type"]:checked').val() === '1' || $('[type="radio"][name="csv_type"]:checked').val() === '3') {
                $('[data-show-if-send-mail="false"]').show();
                $('[data-show-if-send-mail="true"]').hide();
            } else {
                $('[data-show-if-send-mail="false"]').hide();
                $('[data-show-if-send-mail="true"]').show();
            }
        };

        /**
         * 出欠の人数をカウントして表示
         */
        ManageList.prototype.countEntry = function () {
            var attend = $('.entry[data-entry="attend"]').size();
            var absent = $('.entry[data-entry="absent"]').size();
            $('[data-count="attend"]').text(attend);
            $('[data-count="absent"]').text(absent);
            $('[data-count="all"]').text(attend + absent);
        };

        /**
         * 決済取り消しのリンク押下処理
         */
        $(document).on('click', '[data-action="cancel_payment"]', function () {
            var $form = $('#cancel_payment_form');
            var entry_id = $(this).closest('.entry').find('input[name="entry_id"]').val();
            $form.find('input[name="entry_id"]').val(entry_id);
            $form.submit();
        });

        /**
         * ONE-Wのコールバック用
         */
        ManageList.prototype.oneWCallback = function () {
            if (!!location.hash) {
                var query = location.hash.replace(/^#/, '');
                var params = new URLSearchParams(query);
                // アクセストークンとstateがフラグメントで指定されている場合はONE-Wからのコールバックとみなし、ゲスト送信処理をコール
                if (params.has('AccessToken') && params.has('state')) {
                    $.ajax({
                        type: 'POST',
                        dataType: 'json',
                        cache: false,
                        timeout: 30000,
                        url: '/manage_list/send_guests',
                        data: {
                            access_token: params.get('AccessToken'),
                            state: params.get('state'),
                        },
                        beforeSend: function () {
                            $.blockUI();
                        }
                    }).done(function (data, textStatus, xhr) {
                        if (data.status === 'OK') {
                            location.href = '/manage_list'; // クエリパラメータを除去してリロード
                        } else {
                            vex.dialog.alert(data.errors[0]);
                        }
                    }).fail(function (xhr, textStatus, errorThrown) {
                        // サーバーエラー
                        vex.dialog.alert('ゲストの送信に失敗しました。もう一度お試しください');
                    }).always(function () {
                        $.unblockUI();
                    });
                }
            }
        };

        return ManageList;
    })();
}).call(this);
