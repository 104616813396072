/**
 * 施設のユーザアカウント作成画面
 */
(function () {
    this.SignupPartnersUser = (function () {
        function SignupPartnersUser() {
            this.init();
        }

        SignupPartnersUser.prototype.init = function () {
            /**
             * Vueオブジェクト
             * @type {Vue}
             */
            var app = new Vue({
                el: '[data-view="signup_partners_user"]',
                /**
                 * ミックスインオブジェクト
                 */
                mixins: [
                    CommonMixin,
                ],
                /**
                 * コンポーネント群
                 */
                components: {
                },
                /**
                 * データ群
                 */
                data: function () {
                    return {
                        errors: [],
                        // 法人のデフォルト招待状一覧
                        invitations: [],
                        // TG専用のデフォルト招待状一覧
                        tg_invitations: [],
                        tg_partner_id: false,
                        searchText: '',
                        suggestions: [],
                        showSuggestions: false,
                        selectedSuggestion:false,
                        selected_sid: ''
                    }
                },
                /**
                 * インスタンス生成時に実行される処理
                 */
                created: function () {
                    // ユーザー情報をjsonから取得
                    this.invitations = JSON.parse(document.querySelector('[data-roll="invitations_json"]').innerHTML);
                    this.tg_invitations = JSON.parse(document.querySelector('[data-roll="tg_invitations_json"]').innerHTML);
                    // 作成する招待状の1つ目は選択状態にする
                    this.invitations[0].checked = 1;
                },
                /**
                 * ウォッチャー
                 */
                watch: {
                },
                /**
                 * 各種メソッド
                 */
                methods: {

                    /**
                     * サジェスション更新
                     */
                    updateSuggestions(e) {
                        var val = e.currentTarget.value;
                        if (!val) {
                            this.showSuggestions = false;
                            this.suggestions = [];
                            return;
                        }

                        // 検索キーワードをカタカナに対応
                        var searchText = val.replace(/[ァ-ン]/g, function(s) {
                            return String.fromCharCode(s.charCodeAt(0) - 0x60);
                        });

                        // サジェスションを絞り込み
                        this.suggestions = JSON.parse(JSON.stringify(this.tg_invitations));
                        this.suggestions = this.suggestions.filter((invitation) => {
                            // 英語の大文字小文字は区別しない
                            return invitation.title.toLowerCase().includes(searchText.toLowerCase());
                        });

                        // サジェスションタイトルから、検索用ひらがな部分を削除
                        this.suggestions.forEach((suggestion, index) => {
                            this.suggestions[index].title = suggestion.title.split(',')[0];
                        });

                        if (this.suggestions.length) {
                            this.showSuggestions = true;
                        }else{
                            this.showSuggestions = false;
                        }
                    },

                    /**
                     * サジェスション選択
                     */
                    selectSuggestion(suggestion,sid,partner_id) {
                        this.selected_sid = sid;
                        this.selectedSuggestion = true;
                        this.searchText = suggestion;
                        this.showSuggestions = false;
                        this.tg_partner_id = partner_id;
                    },

                    /**
                     * TGの会場選択状態を解除
                     */
                    cancelTgPlace() {
                        this.selected_sid = '';
                        this.selectedSuggestion = false;
                        this.showSuggestions = false;
                    },

                    /**
                     * アカウント作成処理を実行
                     */
                    submit: function () {
                        var that = this;
                        var form = this.$el.querySelector('#create_form');
                        // VueのDOM更新は非同期のため、DOMの更新が全て終わってからフォーム処理を行う
                        Vue.nextTick(function () {
                            if (that.validate(form)) {
                                var userNames = form.querySelector('input[name="name1_1"]').value +
                                form.querySelector('input[name="name1_2"]').value + "・" +
                                form.querySelector('input[name="name2_1"]').value +
                                form.querySelector('input[name="name2_2"]').value;
                                form.querySelector('input[name="name"]').value = userNames;

                                if (that.tg_partner_id){
                                    form.querySelector('input[name="partner_id"]').value = that.tg_partner_id;
                                }

                                vex.dialog.confirm({
                                    message: 'アカウントを作成します。よろしいですか？',
                                    callback: function (value) {
                                        if (value === true) {
                                            that.$_submitForm(form);
                                        }
                                    }
                                });
                            }
                        });
                    },

                    /**
                     * バリデーションを行う
                     */
                    validate: function (form) {
                        this.errors = [];

                        // おふたりのお名前
                        if (validator.isBlank(form.querySelector('input[name="name1_1"]').value) ||
                            validator.isBlank(form.querySelector('input[name="name1_2"]').value) ||
                            validator.isBlank(form.querySelector('input[name="name2_1"]').value) ||
                            validator.isBlank(form.querySelector('input[name="name2_2"]').value)
                        ) {
                            this.errors.push('おふたりのお名前を入力してください');
                        }
                        // メールアドレス
                        if (validator.isBlank(form.querySelector('input[name="email"]').value)) {
                            this.errors.push('メールアドレスを入力してください');
                        }
                        // パスワード
                        if (validator.isBlank(form.querySelector('input[name="password"]').value)) {
                            this.errors.push('パスワードを入力してください');
                        }
                        // 開催日
                        if (validator.isBlank(form.querySelector('input[name="event_date"]').value)) {
                            this.errors.push('開催日を入力してください');
                        }

                        // 作成する招待状の選択
                        var exists = false;
                        for (var i = 0; i < this.invitations.length; i++) {
                            if (this.invitations[i].checked) {
                                exists = true;
                                break;
                            }
                        }
                        if (!exists) {
                            this.errors.push('作成する招待状を選択してください');
                        }

                        // 担当者
                        if (validator.isBlank(form.querySelector('input[name="partner_staff"]').value)) {
                            this.errors.push('担当者を入力してください');
                        }

                        // T&G対応
                        if (form.querySelector('.tg_place') && !form.querySelector('input[name="invitations[]"]')) {
                            this.errors.push('会場を選択してください');
                        }

                        // 利用するサービス
                        if (form.querySelector('input[name="use_web_table"]') && !form.querySelector('input[name="use_web_table"]:checked')) {
                            this.errors.push('利用するサービスを選択してください');
                        }

                        return this.errors.length === 0;
                    },
                }
            });
        };

        return SignupPartnersUser;
    })();
}).call(this);
