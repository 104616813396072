/**
 * ログイン画面用のJS
 */
(function () {
    this.SignIn = (function () {
        function SignIn() {
            this.setListener();

            if (window != parent) {
                top.location.href = "/login";
            }
        }

        SignIn.prototype.setListener = function () {
            // do nothing
        };

        return SignIn;
    })();
}).call(this);
