/**
 * 管理画面トップ
 */
(function () {
    this.ManageInvitation = (function () {
        function ManageInvitation() {
            $('[data-role="change_invitaiton_name"]').text() === 'true' && this.openActionModal();
            this.isPartnerDefaultUser = $('[data-role="is_partner_default_user"]').text() === 'true';
            this.setEventListener();
            this.setupTab();
        }

        var common = new Common();

        /**
         * イベントリスナーの定義
         */
        ManageInvitation.prototype.setEventListener = function () {
            var _this = this;

            // 招待状の各種アクション用モーダル表示
            $('[data-action="open_invitation_modal"]').click((function () {
                _this.openActionModal();
            }));

            // 席次表の案内モーダル表示
            $('[data-action="open_web_table_modal"]').click((function () {
                if (!_this.webTableModal) {
                    var $elem = $('[data-remodal-id="web_table_remodal"]');
                    _this.webTableModal = $elem.remodal({
                        hashTracking: false,
                        modifier: 'l-remodal remodal-base remodal-full-height web-table-modal', // classをオーバーライド
                    });
                    $elem.on('opening', function() {
                        if (!_this.webTableModalSwiper) {
                            // swiperのセットアップ
                            _this.webTableModalSwiper = new Swiper('.web-table-modal-swiper', {
                                // autoplayにバグがありモーダルを1度閉じると復帰できないのでautoplay無し
                                slidesPerView: 1,
                                spaceBetween: 20,
                                centeredSlides : true,
                                loop: true,
                                pagination: {
                                    el: '.swiper-pagination',
                                    type: 'bullets',
                                    clickable: true,
                                },
                            });
                        }
                    });
                }
                _this.webTableModal.open();
            }));

            // 招待状作成
            $('[data-action="create_invitation"]').click(function () {
                _this.createInvitation();
            });

            // 招待状複製
            $('[data-action="copy_invitation"]').click(function () {
                _this.copyInvitation($(this));
            });

            // 招待状削除
            $('[data-action="delete_invitation"]').click(function () {
                _this.deleteInvitation($(this), '');
            });

            // 招待状名変更
            $('[data-action="change_invitation"]').click(function () {
                const invitationName = $(this).closest('[data-role="invitation_action"]').find('input[name="invitation_name"]').val();
                _this.changeInvitation($(this), invitationName, '');
            });

            // 招待状の各種アクション用モーダル表示
            $('[data-action="open_postponed_modal"]').click((function () {
                $('[data-remodal-id="postponed_modal"]').remodal({
                    hashTracking: false,
                }).open();
            }));

            // 席次表の利用開始
            $('[data-action="submit_table"]').click((function () {
                vex.dialog.buttons.YES.className = 'vex-dialog-button-table-primary';
                var $form = $('[data-role="submit_table_form"]');
                var confirm_message = $(this).data('confirm');
                vex.closeAll(); // 連打されたとき用に表示中のダイアログを閉じる
                vex.dialog.confirm({
                    message: confirm_message,
                    callback: function (value) {
                        if (value === true) {
                            $.blockUI();
                            $form.submit();
                        }
                    }
                });
            }));
        };

        /**
         * 招待状の各種アクション用モーダル表示
         */
        ManageInvitation.prototype.openActionModal = function () {
            $('[data-remodal-id="invitation_action_remodal"]').remodal({
                hashTracking: false,
                modifier: 'l-remodal remodal-base invitation-action-modal', // classをオーバーライド
            }).open();
        };

        /**
         * タブの表示位置を調整する
         */
        ManageInvitation.prototype.setupTab = function () {
            var $activeItem = $('[data-role="header_tab_item"].active');
            var tabIndex = Number($('[data-role="header_tab_item"]').index($activeItem)) + 1;
            var tabWidth = $('[data-role="header_tab_item"]').outerWidth();
            // タブの位置までスクロールする
            if ((tabIndex > 3) && (tabWidth > 0)) {
                $('[data-role="header_tab"]').scrollLeft((tabWidth * (tabIndex - 3)) + (tabWidth * ((100 - 27.5) / 100)));
            }
        };

        /**
         * 招待状を作成する
         */
        ManageInvitation.prototype.createInvitation = function () {
            if (!this.isPartnerDefaultUser && $('[data-role="invitation_action"]').length >= 5) {
                swalToast('招待状の上限数は5つです', {icon: 'warning'});
                return;
            }
            vex.dialog.buttons.YES.text = '作成する';
            vex.dialog.buttons.YES.className = 'vex-dialog-button-primary';
            var $form = $('[data-role="create_invitation_form"]');
            vex.closeAll(); // 連打されたとき用に表示中のダイアログを閉じる
            vex.dialog.confirm({
                message: '新しく作成します。よろしいですか？',
                callback: function (value) {
                    if (value === true) {
                        $.blockUI();
                        $form.submit();
                    }
                }
            });
        };

        /**
         * 招待状を複製する
         */
        ManageInvitation.prototype.copyInvitation = function ($btn) {
            if (!this.isPartnerDefaultUser && $('[data-role="invitation_action"]').length >= 5) {
                swalToast('招待状の上限数は5つです', {icon: 'warning'});
                return;
            }
            vex.dialog.buttons.YES.text = '複製する';
            vex.dialog.buttons.YES.className = 'vex-dialog-button-primary';
            var $form = $btn.closest('[data-role="invitation_action"]').find('[data-role="copy_invitation_form"]');
            vex.closeAll(); // 連打されたとき用に表示中のダイアログを閉じる
            vex.dialog.confirm({
                message: '招待状を複製します。よろしいですか？',
                callback: function (value) {
                    if (value === true) {
                        $.blockUI();
                        $form.submit();
                    }
                }
            });
        };

        /**
         * 招待状を削除する
         */
        ManageInvitation.prototype.deleteInvitation = function ($btn, message) {
            var _this = this;
            vex.closeAll(); // 連打されたとき用に表示中のダイアログを閉じる
            vex.dialog.buttons.YES.text = '削除';
            vex.dialog.buttons.YES.className = 'vex-dialog-button-inactive';
            var name = $btn.closest('[data-role="invitation_action"]').find('input[name="invitation_name"]').val();
            var sid = $btn.closest('[data-role="invitation_action"]').find('input[name="sid"]').val();
            var entries_count = $btn.closest('[data-role="invitation_action"]').find('input[name="entries_count"]').val();
            var confirm_message = '';
            if (entries_count > 0) {
                confirm_message = 'この招待状には'+entries_count+'名分のゲスト回答があります。<br>';
            }
            vex.dialog.open({
                unsafeMessage: '「'+ name +'」を削除します。<br><font color="#E56473">'+confirm_message+'削除されたデータを復元することはできません。</font><br>問題なければ<b>「削除」</b>と入力してください。<br><span class="error_msg">' + message + '</span>',
                input: [
                    '<input type="text" name="keyword" placeholder="削除" data-action="enter_disable" class="hoge" id="keyword-field" enterkeyhint="done"/>',
                ].join(''),
                afterOpen: function(event) {
                    $('input[name=keyword]').on('keyup input', function(event) {
                        var val = $(this).val();
                        if(val === "削除"){
                            $(':submit').removeClass('vex-dialog-button-inactive');
                            $(':submit').addClass('vex-dialog-button-primary');
                        } else {
                            $(':submit').removeClass('vex-dialog-button-primary');
                            $(':submit').addClass('vex-dialog-button-inactive');
                        }
                    });
                },
                callback: function (value) {
                    if (value) {
                        common.ajaxSubmit('/manage_invitation/delete/' + sid, 'post', value, {
                            error: function(data, textStatus, xhr) {
                                // 入力エラー時は再度ダイアログ表示
                                if (data.disabled) {
                                    vex.closeAll();
                                    vex.dialog.buttons.YES.className = 'vex-dialog-button-primary';
                                    vex.dialog.alert({
                                        unsafeMessage: data.errors[0],
                                    });
                                } else {
                                    _this.deleteInvitation($btn, data.errors[0]);
                                }
                            },
                            fail: function(message) {
                                _this.deleteInvitation($btn, message);
                            }
                        });
                    }
                }
            });
        };

        /**
         * 招待状の名前を変更する
         */
        ManageInvitation.prototype.changeInvitation = function ($btn, invitationName, message) {
            var _this = this;
            var sid = $btn.closest('[data-role="invitation_action"]').find('input[name="sid"]').val();
            vex.closeAll(); // 連打されたとき用に表示中のダイアログを閉じる
            vex.dialog.buttons.YES.text = '変更する';
            vex.dialog.buttons.YES.className = 'vex-dialog-button-primary';
            vex.dialog.open({
                unsafeMessage: '<div class="change-invitation-name">招待状名</div><span class="error_msg">' + message + '</span>',
                input: [
                    '<input type="text" name="invitation_name" placeholder="招待状名" value="' + invitationName + '"/>',
                ].join(''),
                callback: function (value) {
                    if (value) {
                        common.ajaxSubmit('/manage_invitation/update/' + sid, 'post', value, {
                            error: function(data, textStatus, xhr) {
                                // 入力エラー時は再度ダイアログ表示
                                if (data.disabled) {
                                    vex.closeAll();
                                    vex.dialog.alert({
                                        unsafeMessage: data.errors[0],
                                    });
                                } else {
                                    _this.changeInvitation($btn, invitationName, data.errors[0]);
                                }
                            },
                            fail: function(message) {
                                _this.changeInvitation($btn, invitationName, message);
                            }
                        });
                    }
                }
            });
        };

        return ManageInvitation;
    })();
}).call(this);
