/**
 * lovegraph LPのJS
 */
(function () {
    this.WithLovegraph = (function () {
        function WithLovegraph() {
            this.setListener();
        }

        WithLovegraph.prototype.setListener = function () {

            if (Clipboard.isSupported()) {
                var clipboard = new Clipboard('[data-action="code"]');
                clipboard.on('success', function() {
                    swalToast('メッセージをコピーしました', {icon: 'success'});
                });
            }
        };
        return WithLovegraph;
    })();
}).call(this);