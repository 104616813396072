/**
 * 法人のユーザー管理画面
 */
(function () {
    this.PartnerUser = (function () {
        function PartnerUser() {
            this.init();
        }

        PartnerUser.prototype.init = function () {
            /**
             * Vueオブジェクト
             * @type {Vue}
             */
            var app = new Vue({
                el: '[data-view="partner_container"]',
                /**
                 * ミックスインオブジェクト
                 */
                mixins: [
                    CommonMixin,
                ],
                /**
                 * コンポーネント群
                 */
                components: {
                    'password-modal': this.passwordModal(),
                    'create-modal': this.createModal(),
                    'show-qr-modal': this.showQrModal(),
                    'limit-date-modal': this.limitDateModal(),
                    'change-user-name-modal': this.changeUserNameModal(),
                    'change-staff-name-modal': this.changeStaffNameModal(),
                },
                /**
                 * データ群
                 */
                data: function () {
                    return {
                        // 法人に紐づくユーザー一覧
                        users: [],
                        filterSortedUsers: [],
                        // 開催日が過ぎた式も表示するフラグ
                        isShowOverDateInvitation: false,
                        // ソート情報
                        sortType: '',
                        sortOrder: 'asc',
                        // エラー一覧
                        errors: [],
                        showSidebar: false,
                        activeNumber: null,
                    }
                },
                /**
                 * インスタンス生成時に実行される処理
                 */
                created: function () {
                    // ユーザー情報をjsonから取得
                    this.users = JSON.parse(document.querySelector('[data-roll="users_json"]').innerHTML);
                    this.filterSortUsers();
                    this.errors = JSON.parse(document.querySelector('[data-roll="errors_json"]').innerHTML);
                },
                /**
                 * ウォッチャー
                 */
                watch: {
                    // フラグが変更されたらユーザーフィルタリング
                    isShowOverDateInvitation: function () {
                        this.filterSortUsers();
                    },
                },
                /**
                 * 各種メソッド
                 */
                methods: {
                    /**
                     * ユーザー一覧のフィルタリングとソートをする
                     */
                    filterSortUsers: function () {
                        this.filterSortedUsers = this.users;
                        // 開催日が過ぎた式も表示するフラグでフィルタリング
                        if (!this.isShowOverDateInvitation) {
                            this.filterSortedUsers = this.users.filter(function (user) {
                                return moment(user.event_date) >= moment().startOf('day');
                            });
                        }
                        // ソート
                        var _sortType  = this.sortType;
                        var _sortOrder = this.sortOrder;
                        if (!!this.sortType) {
                            this.filterSortedUsers.sort(function (a, b) {
                                var valueA = a[_sortType];
                                var valueB = b[_sortType];

                                if ((valueA > valueB) || !valueB) {
                                    if (_sortOrder === 'asc') {
                                        return 1;
                                    } else {
                                        return -1;
                                    }
                                } else if ((valueA < valueB) || !valueA) {
                                    if (_sortOrder === 'asc') {
                                        return -1;
                                    } else {
                                        return 1;
                                    }
                                }
                                return 0;
                            });
                        }
                    },
                    /**
                     * 回答受付中の文字列を返す
                     * @param invitation
                     * @return {string}
                     */
                    acceptEntryLabel: function (invitation) {
                        if (Number(invitation.created) === 0) {
                            // 作成済みでない場合は未対応
                            return '未対応';
                        }
                        if (!invitation.limit_date) {
                            // 回答期限が無い場合は-
                            return '-';
                        }
                        if (Number(invitation.entry_accept) === 1) {
                            // 回答期限を過ぎても受け付ける場合はいつでも受付中
                            return '受付中';
                        }
                        if (moment(invitation.limit_date) >= moment().startOf('day')) {
                            // 回答期限内は受付中
                            return '受付中';
                        }

                        return '締切';
                    },

                    /**
                     * ソート用アイコンのクラス名を返す
                     * @param type
                     * @return {string}
                     */
                    sortIconClass: function (type) {
                        // ソート中か否か、また昇順/降順でクラス分け
                        if (this.sortType === type) {
                            if (this.sortOrder === 'asc') {
                                return 'fa-sort-up active';
                            } else {
                                return 'fa-sort-down active';
                            }
                        }

                        return 'fa-sort';
                    },

                    /**
                     * ソートを切り替える
                     * @param type
                     */
                    changeSort: function (type) {
                        if (this.sortType === type) {
                            // 現在選択中のタイプを選んだ場合は昇順/降順をトグル
                            if (this.sortOrder === 'asc') {
                                this.sortOrder = 'desc';
                            } else {
                                this.sortOrder = 'asc';
                            }
                        } else {
                            // 現在選択中でないタイプを選んだ場合はそのタイプに切替え
                            this.sortType = type;
                            this.sortOrder = 'asc';
                        }

                        this.filterSortUsers();
                    },

                    /**
                     * 新郎新婦のアカウントを削除する
                     * ※誤って登録してしまった場合や、ドメイン制限でアカウント作成メールが送れなかった場合への対応
                     * @param user
                     */
                    removeUser: function (user) {
                        var that = this;

                        if (!user.deletable) {
                            vex.dialog.alert('新郎新婦が招待状を編集済みのため削除できません');
                        } else {
                            vex.dialog.confirm({
                                unsafeMessage: '新郎新婦のアカウントを削除します。本当によろしいですか？<br>' +
                                    '（新郎新婦が招待状を一度も編集していない場合のみ削除が可能です）',
                                callback: function (value) {
                                    if (value === true) {
                                        var form = that.$el.querySelector('form#remove_user_form');
                                        form.querySelector('input[name=uid]').value = user.uid;
                                        // VueのDOM更新は非同期のため、DOMの更新が全て終わってからフォーム処理を行う
                                        Vue.nextTick(function () {
                                            that.errors = [];
                                            $.blockUI();
                                            form.submit();
                                        });
                                    }
                                }
                            });
                        }
                    },

                    /**
                     * 席次表を有効にする
                     * @param uid
                     */
                    activeTable: function (uid) {
                        var that = this;
                        vex.dialog.confirm({
                            unsafeMessage: '【Web招待状プラン】から【Web招待状＋Web席次表プラン】に変更します。よろしいですか？',
                            callback: function (value) {
                                if (value === true) {
                                    var form = that.$el.querySelector('form#active_table_form');
                                    form.querySelector('input[name=uid]').value = uid;
                                    // VueのDOM更新は非同期のため、DOMの更新が全て終わってからフォーム処理を行う
                                    Vue.nextTick(function () {
                                        that.errors = [];
                                        $.blockUI();
                                        form.submit();
                                    });
                                }
                            }
                        });
                    },

                    /**
                     * 席次表を削除する
                     * @param table
                     */
                    removeTable: function (table) {
                        var that = this;
                        if (table.has_created === '1') {
                            vex.dialog.alert('新郎新婦が席次表を編集済みのため削除できません');
                        }else{
                            vex.dialog.confirm({
                                unsafeMessage: 'Web席次表を削除します。本当によろしいですか？<br>' +
                                    '（新郎新婦が席次表を一度も編集していない場合のみ削除が可能です）',
                                callback: function (value) {
                                    if (value === true) {
                                        var form = that.$el.querySelector('form#remove_table_form');
                                        form.querySelector('input[name=uid]').value = table.user_id;
                                        // VueのDOM更新は非同期のため、DOMの更新が全て終わってからフォーム処理を行う
                                        Vue.nextTick(function () {
                                            that.errors = [];
                                            $.blockUI();
                                            form.submit();
                                        });
                                    }
                                }
                            });
                        }
                    },

                    /**
                     * CSVダウンロード可能な招待状かを判定
                     * @param invitation
                     * @return {boolean}
                     */
                    isDownloadable: function (invitation) {
                        // 回答があるもののみtrueを返す
                        return invitation.entry_count > 0;
                    },

                    openSidebar: function () {
                        this.showSidebar = true;
                    },
                    closeSidebar: function () {
                        this.showSidebar = false;
                    },

                    /**
                     * 選択した行の色を変える
                     * @param num
                     */
                    isActive: function (number) {
                        if (number == this.activeNumber) {
                            return true;
                        }
                    },
                    onBlur: function() {
                        this.activeNumber =null;
                    }
                }
            });
        };

        /**
         * 新郎新婦アカウント登録用モーダルのVueコンポーネント
         */
        PartnerUser.prototype.createModal = function () {
            return {
                /**
                 * テンプレート
                 */
                template: '#partner-user-input-modal-template',

                /**
                 * ミックスインオブジェクト
                 */
                mixins: [
                    CommonMixin,
                ],
                /**
                 * データ群
                 */
                data: function () {
                    return {
                        errors: [],

                        // 法人のデフォルト招待状一覧
                        invitations: [],
                    }
                },
                /**
                 * インスタンス生成時に実行される処理
                 */
                created: function () {
                    // 招待状一覧情報をjsonから取得して整形
                    var invitation_all = JSON.parse(document.querySelector('[data-roll="invitations_json"]').innerHTML);

                    // 招待状一覧からお礼状を除く
                    var invitations = [];
                    for (var i = 0; i < invitation_all.length; i++) {
                        if (Number(invitation_all[i].wtype) === 20 || Number(invitation_all[i].wtype) === 30) {
                            invitations.push(invitation_all[i]);
                        }
                    }

                    for (var i = 0; i < invitations.length; i++) {
                        if(invitations.length === 1){
                            invitations[i].checked = true;
                        }else{
                            invitations[i].checked = false;
                        }
                        // 検索用のふりがなを削除
                        invitations[i].title = invitations[i].title.split(',')[0];
                        // 時間関連
                        invitations[i].calc_time_value_1 = '';
                        invitations[i].time_1_hour = '';
                        invitations[i].time_1_minute = '';
                        invitations[i].calc_time_value_2 = '';
                        invitations[i].time_2_hour = '';
                        invitations[i].time_2_minute = '';
                        if (Number(invitations[i].wtype) === 20) {
                            invitations[i].calc_time_value_3 = '';
                            invitations[i].time_3_hour = '';
                            invitations[i].time_3_minute = '';
                            invitations[i].calc_time_value_4 = '';
                            invitations[i].time_4_hour = '';
                            invitations[i].time_4_minute = '';
                        }
                    }
                    this.invitations = invitations;
                },
                /**
                 * 各種メソッド
                 */
                methods: {
                    open: function () {
                        $('.add_invitation').addClass("active");
                    },
                    close: function () {
                        $('.add_invitation').removeClass("active");
                    },

                    /**
                     * formがsubmitした際に呼ばれる。
                     * UPDATE処理を実行
                     * @param e
                     */
                    onSubmit: function (e) {
                        var that = this;
                        var form = e.currentTarget;
                        // VueのDOM更新は非同期のため、DOMの更新が全て終わってからフォーム処理を行う
                        Vue.nextTick(function () {
                            if (that.validate(form)) {
                                vex.dialog.confirm({
                                    message: 'アカウントを作成し、新郎新婦へメールを送信します。よろしいですか？',
                                    callback: function (value) {
                                        if (value === true) {
                                            that.$_submitForm(form);
                                        }
                                    }
                                });
                            }
                        });
                    },

                    /**
                     * バリデーションを行う
                     */
                    validate: function (form) {
                        this.errors = [];

                        var exists = false;
                        for (var i = 0; i < this.invitations.length; i++) {
                            if (this.invitations[i].checked) {
                                exists = true;
                                break;
                            }
                        }
                        if (!exists) {
                            this.errors.push('作成する招待状を選択してください');
                        }
                        if (validator.isBlank(form.querySelector('input[name="event_date"]').value)) {
                            this.errors.push('開催日を入力してください');
                        }
                        if (validator.isBlank(form.querySelector('input[name="name"]').value)) {
                            this.errors.push('新郎新婦氏名を入力してください');
                        }
                        if (validator.isBlank(form.querySelector('input[name="email"]').value)) {
                            this.errors.push('新郎新婦メールアドレスを入力してください');
                        }
                        if (validator.isBlank(form.querySelector('input[name="invitation_title"]').value)) {
                            this.errors.push('招待状に表示するお名前を入力してください');
                        }
                        if (validator.isBlank(form.querySelector('input[name="limit_date"]').value)) {
                            this.errors.push('招待状の回答期限日を入力してください');
                        }
                        if (validator.isBlank(form.querySelector('input[name="partner_staff"]').value)) {
                            this.errors.push('担当者を入力してください');
                        }
                        if (form.querySelector('input[name="use_web_table"]') && !form.querySelector('input[name="use_web_table"]:checked')) {
                            this.errors.push('利用するサービスを選択してください');
                        }

                        return this.errors.length === 0;
                    },

                    /**
                     * 時間を変更した際に呼ぶ処理
                     * @param index invitationsのindex値
                     * @param timePosition 時間のポジション値
                     * @param hourChanged 時間（hour）を変更したか否か
                     */
                    changeTime: function (index, timePosition, hourChanged) {
                        var hour = this.invitations[index]['time_' + timePosition + '_hour'];
                        var minute = this.invitations[index]['time_' + timePosition + '_minute'];
                        // 時間を変更した際に、分が未入力の場合は00をバインド
                        if (hourChanged && minute === '') {
                            minute = '00';
                            Vue.set(this.invitations[index], 'time_' + timePosition + '_minute', '00');
                        }
                        var time = '';
                        if (hour && minute) {
                            time = hour + ':' + minute + ':00';
                        }
                        Vue.set(this.invitations[index], 'calc_time_value_' + timePosition, time);
                    }
                }
            };
        };

        /**
         * QRコード表示用モーダルのVueコンポーネント
         */
        PartnerUser.prototype.showQrModal = function () {
            return {
                /**
                 * テンプレート
                 */
                template: '#partner-user-show-qr-modal-template',

                /**
                 * ミックスインオブジェクト
                 */
                mixins: [
                    CommonMixin,
                ],
                /**
                 * データ群
                 */
                data: function () {
                    return {
                        admUrl: '',
                        sid: '',
                        wtype: null,
                        errors: [],
                    }
                },
                /**
                 * 算出プロパティ
                 */
                computed: {
                    // QRコードのURL
                    qrUrl: function () {
                        return this.admUrl + '/qr/invitation?v=' + this.sid;
                    }
                },
                /**
                 * インスタンス生成時に実行される処理
                 */
                created: function () {
                    // 情報をhtmlから取得
                    this.admUrl = document.querySelector('[data-role="adm_url"]').innerHTML;
                },
                /**
                 * 画面描画後に呼び出される処理
                 */
                mounted: function () {
                    // URLコピー
                    var clipboard = new Clipboard('[data-action="copy_url"]');
                    clipboard.on('success', function() {
                        swalToast('QRコード画像のURLをコピーしました', {icon: 'success'});
                    });
                },
                /**
                 * 各種メソッド
                 */
                methods: {
                    open: function (invitation) {
                        this.sid = invitation.sid;
                        this.wtype = invitation.wtype;
                        $(this.$el).remodal({hashTracking: false}).open();
                    },
                }
            };
        };

        /**
         * 回答期限日変更用モーダルのVueコンポーネント
         */
        PartnerUser.prototype.limitDateModal = function () {
            return {
                /**
                 * テンプレート
                 */
                template: '#partner-user-limit-date-modal-template',

                /**
                 * ミックスインオブジェクト
                 */
                mixins: [
                    CommonMixin,
                ],
                /**
                 * データ群
                 */
                data: function () {
                    return {
                        invitation: {},
                        errors: [],
                    }
                },
                /**
                 * 各種メソッド
                 */
                methods: {
                    open: function (invitation) {
                        this.invitation = JSON.parse(JSON.stringify(invitation)); // 参照を解除して代入
                        $(this.$el).remodal({hashTracking: false}).open();
                    },

                    /**
                     * formがsubmitした際に呼ばれる。
                     * UPDATE処理を実行
                     * @param e
                     */
                    onSubmit: function (e) {
                        var that = this;
                        var form = e.currentTarget;
                        // VueのDOM更新は非同期のため、DOMの更新が全て終わってからフォーム処理を行う
                        Vue.nextTick(function () {
                            that.$_submitForm(form);
                        });
                    },
                }
            };
        };

        /**
         * ログインパスワード変更用モーダルのVueコンポーネント
         */
        PartnerUser.prototype.passwordModal = function () {
            return {
                /**
                 * テンプレート
                 */
                template: '#partner-change-password-modal-template',

                /**
                 * ミックスインオブジェクト
                 */
                mixins: [
                    CommonMixin,
                ],
                /**
                 * データ群
                 */
                data: function () {
                    return {
                        errors: [],
                    }
                },
                /**
                 * 各種メソッド
                 */
                methods: {
                    open: function () {
                        $(this.$el).remodal({hashTracking: false}).open();
                    },

                    /**
                     * formがsubmitした際に呼ばれる。
                     * UPDATE処理を実行
                     * @param e
                     */
                    onSubmit: function (e) {
                        var that = this;
                        var form = e.currentTarget;
                        // VueのDOM更新は非同期のため、DOMの更新が全て終わってからフォーム処理を行う
                        Vue.nextTick(function () {
                            if (that.validate(form)) {
                                vex.dialog.confirm({
                                    message: 'ログインパスワードを変更します。よろしいですか？',
                                    callback: function (value) {
                                        if (value === true) {
                                            that.$_submitForm(form);
                                        }
                                    }
                                });
                            }
                        });
                    },

                    /**
                     * バリデーションを行う
                     */
                    validate: function (form) {
                        this.errors = [];

                        if (validator.isBlank(form.querySelector('input[name="password"]').value)) {
                            this.errors.push('パスワードを入力してください');
                        }
                        if (validator.isBlank(form.querySelector('input[name="password_confirmation"]').value)) {
                            this.errors.push('パスワード（確認用）を入力してください');
                        }

                        return this.errors.length === 0;
                    },
                }
            };
        };

        /**
         * 新郎新婦名前変更用モーダルのVueコンポーネント
         */
        PartnerUser.prototype.changeUserNameModal = function () {
            return {
                /**
                 * テンプレート
                 */
                template: '#partner-user-change-user-name-modal-template',

                /**
                 * ミックスインオブジェクト
                 */
                mixins: [
                    CommonMixin,
                ],
                /**
                 * データ群
                 */
                data: function () {
                    return {
                        invitation: {},
                        user: {},
                        errors: [],
                    }
                },
                /**
                 * 各種メソッド
                 */
                methods: {
                    open: function (invitation, user) {
                        this.invitation = JSON.parse(JSON.stringify(invitation)); // 参照を解除して代入
                        this.user = JSON.parse(JSON.stringify(user));
                        $(this.$el).remodal({hashTracking: false}).open();
                    },

                    /**
                     * formがsubmitした際に呼ばれる。
                     * UPDATE処理を実行
                     * @param e
                     */
                    onSubmit: function (e) {
                        var that = this;
                        var form = e.currentTarget;
                        // VueのDOM更新は非同期のため、DOMの更新が全て終わってからフォーム処理を行う
                        Vue.nextTick(function () {
                            that.$_submitForm(form);
                        });
                    },
                }
            };
        };

        /**
         * 新郎新婦名前変更用モーダルのVueコンポーネント
         */
        PartnerUser.prototype.changeStaffNameModal = function () {
            return {
                /**
                 * テンプレート
                 */
                template: '#partner-user-change-staff-name-modal-template',

                /**
                 * ミックスインオブジェクト
                 */
                mixins: [
                    CommonMixin,
                ],
                /**
                 * データ群
                 */
                data: function () {
                    return {
                        invitation: {},
                        user: {},
                        errors: [],
                    }
                },
                /**
                 * 各種メソッド
                 */
                methods: {
                    open: function (invitation, user) {
                        this.invitation = JSON.parse(JSON.stringify(invitation)); // 参照を解除して代入
                        this.user = JSON.parse(JSON.stringify(user));
                        $(this.$el).remodal({hashTracking: false}).open();
                    },

                    /**
                     * formがsubmitした際に呼ばれる。
                     * UPDATE処理を実行
                     * @param e
                     */
                    onSubmit: function (e) {
                        var that = this;
                        var form = e.currentTarget;
                        // VueのDOM更新は非同期のため、DOMの更新が全て終わってからフォーム処理を行う
                        Vue.nextTick(function () {
                            that.$_submitForm(form);
                        });
                    },
                }
            };
        };

        return PartnerUser;
    })();
}).call(this);
