/**
 * 回答項目クラス
 */
(function () {
    this.ManageForm = (function () {
        function ManageForm() {
            this.setListener();
        }

        ManageForm.prototype.setListener = function () {

            
            /**
             * 自由項目表示制御(旧招待状)
             */
            // 初期表示
            if(!$('ul.old_design #check_free').prop("checked")){
                $('ul.old_design [name=free_form]').css('display','none');
            }
            // チェックボックス選択時の切り替え
            $(document).on('click', 'ul.old_design input[name=check_free]', function () {
                $('ul.old_design [name=free_form]').slideToggle();
            });

            /**
             * 自由項目表示制御(新招待状)
             */
            // 自由項目追加ボタン
            $(document).on('click', 'ul.new_design li.free_add' , function() {
                var new_form_index = $('ul.new_design li.free_content').length+1;
                var new_form_html =  '\
                    <li class="free_content free_'+new_form_index+'">\
                        <div class="left free_form">\
                        <input type="text" name="free_form_'+new_form_index+'" value="" placeholder="質問内容をご入力下さい">\
                        </div>\
                        <div class="free_selector">\
                        <label class="inline_radio_label">\
                        <input type="radio" name="check_free_'+new_form_index+'" value="2" ><span>必須</span>\
                        </label>\
                        <label class="inline_radio_label">\
                            <input type="radio" name="check_free_'+new_form_index+'" value="1" checked="checked"><span>任意</span>\
                        </label>\
                        <button type="button" class="delete_free_form">削除 <i class="ion-android-close"></i></button>\
                        </div>\
                    </li>';
                $('ul.new_design li.free_add').before(new_form_html);
                if(new_form_index === 3){
                    $('ul.new_design li.free_add').css('display','none');
                }
            });

            // 自由項目削除ボタン
            $(document).on('click', 'ul.new_design button.delete_free_form' , function() {
                $(this).parents('li.free_content').remove();
                $('li.free_content').each(function(index, element){
                    $(element).attr('class', 'free_content free_'+(index+1));
                    $(element).find('.free_form input').attr('name', 'free_form_'+(index+1));
                    $(element).find('.free_selector input').attr('name', 'check_free_'+(index+1));
                });
                $('ul.new_design li.free_add').css('display','flex');
            });

            // 初期表示 自由質問の追加ボタン
            if($('ul.new_design li.free_3').length){
                $('ul.new_design li.free_add').css('display','none');
            }

            /**
             * 備考表示制御
             */
            $(document).on('click', 'input[name=is_remarks_enable]', function () {
                $('[name=remarks]').slideToggle();
            });

            /**
             * メールアドレス非表示の場合、自動返信メールを入力させない
             */
            changeMailForm();
            $(document).on('click', 'input[name=mail]', function () {
                changeMailForm();
            });
            function changeMailForm(){
                var mail_selected = $("#update_form").find('input[name="mail"]:checked').val();
                if(mail_selected === '0'){
                    if(!$(".guest_mail").hasClass('hidden_mail')){
                        $(".guest_mail").addClass('hidden_mail');
                        $(".guest_mail textarea").prop( 'disabled', true );
                    }
                }else{
                    if($(".guest_mail").hasClass('hidden_mail')){
                        $(".guest_mail").removeClass('hidden_mail');
                        $(".guest_mail textarea").prop( 'disabled', false );
                    }
                }
            }

            /**
             * その他のお知らせ　表示制御
             */
            $(document).on('click', 'input[name=is_notice]', function () {
                $('div[class=notice_box]').slideToggle();
            });

            /**
             * プレビューボタンクリック時
             */
            $(document).on('click', '[data-action="preview"]', function () {
                var $form_preview = $('#form_preview');
                var $form_update = $('#update_form');

                $form_preview.find('[name="kana"]').val($form_update.find('[name="kana"]:checked').val());
                $form_preview.find('[name="mail"]').val($form_update.find('[name="mail"]:checked').val());
                $form_preview.find('[name="name_latin"]').val($form_update.find('[name="name_latin"]:checked').val());
                $form_preview.find('[name="guest_type"]').val($form_update.find('[name="guest_type"]:checked').val());
                $form_preview.find('[name="sex"]').val($form_update.find('[name="sex"]:checked').val());
                $form_preview.find('[name="post_code"]').val($form_update.find('[name="post_code"]:checked').val());
                $form_preview.find('[name="address"]').val($form_update.find('[name="address"]:checked').val());
                $form_preview.find('[name="tel"]').val($form_update.find('[name="tel"]:checked').val());
                $form_preview.find('[name="allergy"]').val($form_update.find('[name="allergy"]:checked').val());
                $form_preview.find('[name="message"]').val($form_update.find('[name="message"]:checked').val());
                $form_preview.find('[name="check_free_1"]').val($form_update.find('[name="check_free_1"]:checked').val());
                $form_preview.find('[name="free_form_1"]').val($form_update.find('[name="free_form_1"]').val());
                $form_preview.find('[name="check_free_2"]').val($form_update.find('[name="check_free_2"]:checked').val());
                $form_preview.find('[name="free_form_2"]').val($form_update.find('[name="free_form_2"]').val());
                $form_preview.find('[name="check_free_3"]').val($form_update.find('[name="check_free_3"]:checked').val());
                $form_preview.find('[name="free_form_3"]').val($form_update.find('[name="free_form_3"]').val());
                $form_preview.find('[name="companion"]').val($form_update.find('[name="companion"]:checked').val());
                $form_preview.find('[name="online"]').val($form_update.find('[name="online"]:checked').val());
                $form_preview.find('[name="is_notice"]').val($form_update.find('[name="is_notice"]:checked').val());
                $form_preview.find('[name="notice"]').val($form_update.find('[name="notice"]').val());
                $form_preview.submit();
            });
            
        };

        return ManageForm;
    })();
}).call(this);